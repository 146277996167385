import * as React from 'react';
import Box from '@mui/material/Box';
import {
  GridRowsProp,
  GridRowModesModel,
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridEventListener,
  GridRowModel,
  GridRowEditStopReasons,
  GridRowId,
} from '@mui/x-data-grid';
import { CustomGridToolbarProps } from '../../../@types/types';
import { Block, Visibility } from '@mui/icons-material';
import { CircularProgress, Tooltip } from '@mui/material';
import { userData } from '../../../Components/User';
import TeachersOperations from '../../../InfraStructure/Teachers/Operations';

type DataProps = {
  data: GridRowsProp;
};

export default function CrudTeachers({ data }: DataProps) {
  const [rows, setRows] = React.useState(data);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [loading, setLoading] = React.useState({ status: false, id: undefined });
  const userInfo = userData();
  const { access, user } = userInfo;

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleVerifyEditMode = (params: any) => {
    return true;
  }

  React.useEffect(() => {
    setRows(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleAccessUser = async (id: GridRowId, email: string) => {
    setLoading({ status: true, id });

    const response = await TeachersOperations.checkUser(email.toLowerCase()) as any;
    if (response.status === 'success') {
      setLoading({ status: false, id: undefined });
      window.location.reload();
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'usuario_id',
      flex: 1,
      editable: false,
      type: 'string',
    },
    {
      field: 'nome',
      minWidth: 220,
      flex: 1,
      editable: false,
      type: 'string',
      headerAlign: 'left',
      renderHeader: () => <strong>Nome</strong>,
    },
    {
      field: 'email',
      minWidth: 280,
      flex: 1,
      editable: false,
      type: 'string',
      headerAlign: 'left',
      renderHeader: () => <strong>E-mail</strong>,
    },
    {
      field: 'telefone',
      minWidth: 150,
      flex: 1,
      editable: false,
      type: 'string',
      headerAlign: 'left',
      renderHeader: () => <strong>Telefone</strong>,
    },
    {
      field: 'cadastro',
      editable: false,
      type: 'boolean',
    },
    {
      field: 'criado_em',
      headerAlign: 'center',
      type: 'string',
      minWidth: 180,
      flex: 1,
      editable: false,
      align: 'center',
      renderHeader: () => <strong>Criado em</strong>,
    },
    access &&
    {
      field: 'actions',
      type: 'actions',
      renderHeader: () => <strong>Ações</strong>,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const { cadastro, email } = rows.find((row) => row.usuario_id === id);

        if ((cadastro === true) && (user.email !== email)) {
          return [
            <Tooltip title="Acessar" arrow>
              <GridActionsCellItem
                icon={(loading.status && loading.id === id) ? <CircularProgress size={20} /> : <Visibility />}
                label="Edit"
                className="textPrimary"
                onClick={() => handleAccessUser(id, email)}
                disabled={loading.status && loading.id === id}
                color="inherit"
              />
            </Tooltip>,
          ];
        } else {
          const msg = (user.email === email) ? 'Você' : 'Não cadastrado';
          return [
            <Tooltip title={msg} arrow>
              <Block style={{ opacity: 0.5 }} />
            </Tooltip>
          ]
        }
      },
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        disableColumnMenu
        onRowDoubleClick={handleVerifyEditMode}
        getRowId={(row) => row.usuario_id}
        autosizeOptions={{
          columns: ['nome', 'email', 'telefone'],
          includeOutliers: true,
          includeHeaders: true,
        }}
        localeText={{
          noRowsLabel: 'Nenhum(a) professor(a) encontrado(a).',
          footerRowSelected: (count) => `${count} usuário(s) selecionado(s)`,
        }}
        columnVisibilityModel={{
          usuario_id: false,
          criado_em: false,
          cadastro: false,
        }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slotProps={{
          toolbar: { setRows, setRowModesModel } as CustomGridToolbarProps,
          pagination: {
            labelRowsPerPage: "Usuários por página",
            labelDisplayedRows: ({ from, to, count }) => `${from}-${to} de ${count}`,
          }
        }}
      />
    </Box>
  );
}