import { UnknownAction } from "redux";
import ExercisesActions from "./Actions";
import ExercisesService from "../../Common/Services/ExercisesService";
import { ExercisesProps, ResponseProps } from "../../Page/Exercises/components/types";

interface Dispatch {
  (action: UnknownAction): void;
}

interface ExercisesOperationsType {
  getExercisesByHomework: (tarefa_id: string) => (dispatch: Dispatch) => Promise<void>;
  getExercise: (id: string) => (dispatch: Dispatch) => Promise<void>;
  getTypes: () => (dispatch: Dispatch) => Promise<void>;
  updateExercises: (data: ExercisesProps) => (dispatch: Dispatch) => Promise<ResponseProps>;
  createExercises: (data: ExercisesProps) => (dispatch: Dispatch) => Promise<ResponseProps>;
  deleteExercises: (id: number) => (dispatch: Dispatch) => Promise<ResponseProps>;
  getTypesByHomework: (tipo_tarefa_id: string) => (dispatch: Dispatch) => Promise<void>;
}

const ExercisesOperations: ExercisesOperationsType = {
  getExercisesByHomework: (tarefa_id) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(ExercisesActions.SetLoading());
    try {
      const response = await ExercisesService.getExercisesByHomework(tarefa_id);
      dispatch(ExercisesActions.SetSuccess(response.data, 'exercises'));
    } catch (error: string | unknown) {
      console.log('Erro buscando exercícios: ', error);
      dispatch(ExercisesActions.SetFailure());
    }
  },
  getExercise: (id) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(ExercisesActions.SetLoading());
    try {
      const response = await ExercisesService.getExercise(id);
      dispatch(ExercisesActions.SetSuccess(response.data, 'exercises'));
    } catch (error: string | unknown) {
      console.log('Erro buscando exercícios: ', error);
      dispatch(ExercisesActions.SetFailure());
    }
  },
  getTypes: () => async (dispatch: Dispatch): Promise<void> => {
    dispatch(ExercisesActions.SetLoading());
    try {
      const response = await ExercisesService.getTypes();
      dispatch(ExercisesActions.SetSuccess(response.data, 'types'));
    } catch (error: string | unknown) {
      console.log('Erro buscando os tipos de exercício: ', error);
      dispatch(ExercisesActions.SetFailure());
    }
  },
  getTypesByHomework: (tipo_tarefa_id) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(ExercisesActions.SetLoading());
    try {
      const response = await ExercisesService.getTypesByHomework(tipo_tarefa_id);
      dispatch(ExercisesActions.SetSuccess(response.data, 'types'));
    } catch (error: string | unknown) {
      console.log('Erro buscando os tipos de exercício: ', error);
      dispatch(ExercisesActions.SetFailure());
    }
  },
  updateExercises: (data: ExercisesProps) => async (dispatch: Dispatch): Promise<ResponseProps> => {
    try {
      const response = await ExercisesService.updateExercises(data);
      return response.data as unknown as ResponseProps;
    } catch (error: string | unknown) {
      console.log('Erro atualizando exercício: ', error);
      throw new Error(typeof error === 'string' ? error : 'Unknown error');
    }
  },
  createExercises: (data: ExercisesProps) => async (dispatch: Dispatch): Promise<ResponseProps> => {
    try {
      const response = await ExercisesService.createExercises(data);
      return response.data as unknown as ResponseProps;
    } catch (error: string | unknown) {
      console.log('Erro atualizando exercício: ', error);
      throw new Error(typeof error === 'string' ? error : 'Unknown error');
    }
  },
  deleteExercises: (id: number) => async (dispatch: Dispatch): Promise<ResponseProps> => {
    try {
      await ExercisesService.deleteExercises(id);
      return { message: 'Exercício deletado com sucesso.', status: 'success' };
    } catch (error: string | unknown) {
      console.log('Erro deletando exercício: ', error);
      throw new Error(typeof error === 'string' ? error : 'Unknown error');
    }
  }
}

export default ExercisesOperations;