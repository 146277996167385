import { Box, Skeleton } from "@mui/material";


export const SkeletonGrid = () => {
  const rows = [1, 2, 3, 4, 5];
  return (
    rows.map((row) => (
      <Box key={row} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 3 }}>
        <Skeleton variant="rectangular" height={30} style={{ flex: 1, minWidth: 80 }} />
        <Skeleton variant="rectangular" height={30} style={{ flex: 1, marginLeft: 20 }} />
        <Skeleton variant="rectangular" height={30} style={{ flex: 1, marginLeft: 20 }} />
        <Skeleton variant="rectangular" height={30} style={{ flex: 1, marginLeft: 20 }} />
        <Skeleton variant="rectangular" height={30} width={30} style={{ marginLeft: 20 }} />
        <Skeleton variant="rectangular" height={30} width={30} style={{ marginLeft: 10 }} />
      </Box>
    ))
  );
};