import style from 'styled-components';

export const Container = style.div`
  min-width: 260px;
  height: 100%;
  overflow-y: scroll;
  background-color: #343585;
  font-family: 'Roboto', sans-serif;
  -ms-overflow-style: none;
  scrollbar-width: none;
  webkit-scrollbar-width: none;

  @media (max-width: 768px) {
    display: none;
  }

  .menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #343585;
    color: white;

    .menu-header-title {
      font-size: 1.2rem;
      width: 100%;
      text-align: center;

      img {
        width: 5rem;
      }
    }
  }

  .menu-body {
    .menu-body-content {
      padding: 1rem 2rem;
      color: #8F82DE;

      .menu-item {
        display: flex;
        align-items: center;
        cursor: pointer;
          
        .menu-item-header {
          display: flex;
          align-items: center;
          padding: 0.5rem;
          width: 100%;
          border-radius: 5px;
          
          .label {
            margin-left: 10px;
            font-size: 1rem;
            font-weight: 500;
          }
        }
      }

      .menu-subitem {
        width: 97%;

        .menu-subitem-header {
          background-color: transparent;
          border: none;
          align-items: center;

          .menu-subitem-header-title {
            font-size: 1rem;
            font-weight: 500;
            padding: 0;
            color: #8F82DE;
            margin-left: 10px;

            .label {
              font-size: 1rem;
              font-weight: 500;
              color: #8F82DE;
              margin-top: 0.2rem;
            }
          }

          .submenu {
            width: 100%;

            .submenu-item {
              display: flex;
              flexdirection: row;
              padding: 0.5rem;
              margin: 0;

              .submenu-item-label {
                font-size: 1rem;
                font-weight: 500;
                margin-left: 10px;
                margin-top: 0.2rem;
              }
          }
        }
      }
    }
`;