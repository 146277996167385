import StudentsActions from "./Actions";
import StudentsService from "../../Common/Services/StudentsService";
import { AppDispatch } from "../store";
import { StudentProps } from "../../Page/Students/components/types";

interface StudentsTypes {
  getStudents: () => (dispatch: AppDispatch) => Promise<void>;
  updateStudent: (data: any) => (dispatch: AppDispatch) => Promise<StudentProps>;
}

const StudentsOperations: StudentsTypes = {
  getStudents: () => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(StudentsActions.SetLoading());
    try {
      const response = await StudentsService.getStudents();
      const data = (response as { data: { data: any } }).data;
      dispatch(StudentsActions.SetSuccess(data.data));
    } catch (error: string | unknown) {
      console.log('Erro buscando alunos: ', error);
      dispatch(StudentsActions.SetFailure());
    }
  },

  updateStudent: (data: StudentProps) => async (dispatch: AppDispatch): Promise<StudentProps> => {
    try {
      const response = await StudentsService.updateStudent(data);
      return response.data as unknown as StudentProps;
    } catch (error: string | unknown) {
      console.log('Erro ao salvar aluno: ', error);
      throw new Error(typeof error === 'string' ? error : 'Unknown error');
    }
  }
}

export default StudentsOperations;