import GamesTypes from './Types';

const GamesActions = {
  SetLoading: () => ({
    type: GamesTypes.SET_GAMES_LOADING
  }),
  SetFailure: () => ({
    type: GamesTypes.SET_GAMES_FAILURE
  }),
  SetSuccess: (payload: any) => ({
    type: GamesTypes.SET_GAMES_SUCCESS,
    payload
  }),
}

export default GamesActions;