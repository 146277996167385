import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import LaunchIcon from '@mui/icons-material/Launch';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
  GridEditSingleSelectCellProps,
  useGridApiContext,
  GridEditSingleSelectCell,
} from '@mui/x-data-grid';
import {
  randomId,
} from '@mui/x-data-grid-generator';
import { navigateToUrl } from 'single-spa';
import Toast from '../../../Common/Helpers/Toast';
import { successToast } from '../../../Common/Helpers/Utils';
import Swal from 'sweetalert2';
import { AudioUploader, ImageUploader, MultipleChapters } from '../../../Components/Medias';
import { TextField, Typography } from '@mui/material';
import { HomeworksProps, ResponseProps } from './types';
import { CustomGridToolbarProps } from '../../../@types/types';
import { AttachFile, Chat, MusicVideo } from '@mui/icons-material';

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
}

type DataProps = {
  data: GridRowsProp;
  types: any;
  chapters: any;
  onSave?: (data: HomeworksProps) => Promise<ResponseProps>;
  onUpdate?: (data: HomeworksProps) => Promise<ResponseProps>;
  onDelete?: (id: GridRowId) => Promise<boolean>;
}

const styles = {
  container: {
    padding: 10,
    width: '100%',
  },
  text: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center' as 'center',
  }
}

export default function CrudHomeworks({ data, types, chapters, onSave, onDelete, onUpdate }: DataProps) {
  const [rows, setRows] = React.useState(data);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState({ id: undefined, loading: false });
  const mode = React.useRef<'view' | 'edit' | 'new'>('view') as React.MutableRefObject<'view' | 'edit' | 'new'>;
  const small = 110;
  const medium = 200;
  const large = 340;
  const [sizeRow, setSizeRow] = React.useState(small);

  function EditToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
      const id = randomId();
      setRows((oldRows) => [...oldRows, {
        id,
        capitulo_id: '',
        tipo_tarefa_id: '',
        conteudo: {},
        criado_em: new Date().toLocaleDateString(),
        isNew: true,
      }]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'capitulo_id' },
      }));

      mode.current = 'new';
    };

    return (
      <GridToolbarContainer>
        <Button
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
          disabled={mode.current !== 'view'}
          onClick={handleClick}>
          Adicionar
        </Button>
      </GridToolbarContainer>
    );
  }

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.escapeKeyDown) {
      setRowModesModel({
        ...rowModesModel,
        [params.id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
      mode.current = 'view';
    }
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };


  const processRowUpdate = async (newRow: GridRowModel) => {

    const updatedRow = {
      ...newRow,
      id: newRow.id,
      isNew: false,
      tipo_tarefa_id: newRow.tipo_tarefa_id,
      capitulo_id: newRow.capitulo_id,
      conteudo: newRow.conteudo,
      criado_em: newRow.criado_em || new Date().getTime(),
    } as HomeworksProps;

    if (mode.current === 'new') {
      const validation = handleValidationData(updatedRow);

      if (!validation.isValid) {
        setOpen(true);
        setMessage(validation.message);
        return;
      }

      setLoading({ id: newRow.id, loading: true });

      const response = await onSave(updatedRow);

      if (response.status === 'success') {
        mode.current = 'view';
        successToast(response.message);
        setRows(rows.map((row) => (row.id === newRow.id ? response.data : row)));
        setLoading({ id: newRow.id, loading: false });
        return response.data;
      } else {
        setOpen(true);
        setMessage(response.message);
        setLoading({ id: newRow.id, loading: false });
      }
    } else {
      setLoading({ id: newRow.id, loading: true });

      const response = await onUpdate(updatedRow);

      if (response.status === 'success') {
        mode.current = 'view';
        successToast(response.message);

        const returnedRow = {
          ...updatedRow,
        }

        setRows(rows.map((row) => (row.id === newRow.id ? returnedRow : row)));
        setLoading({ id: newRow.id, loading: false });
        return returnedRow;
      } else {
        setOpen(true);
        setMessage(response.message);
        setLoading({ id: newRow.id, loading: false });
      }
    }
  };

  const handleValidationData = (data) => {
    const { capitulo_id, tipo_tarefa_id, conteudo } = data;

    if (!capitulo_id) {
      return ({ isValid: false, message: 'Capitúlo é obrigatório' });
    }
    if (!tipo_tarefa_id) {
      return ({ isValid: false, message: 'Tipo é obrigatório' });
    }
    if (!conteudo || Object.keys(conteudo).length === 0) {
      return ({ isValid: false, message: 'Conteúdo é obrigatório' });
    }
    return ({ isValid: true, message: '' });
  }


  const handleDetailClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel });
    navigateToUrl(`/tarefas/${id}`);
  };

  const handleEditClick = (id: GridRowId) => () => {
    if (mode.current === 'edit') {
      setMessage('Não é possível editar mais de um registro por vez.');
      setOpen(true);
      return;
    }
    mode.current = 'edit';
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    Swal.fire({
      title: 'Atenção',
      text: "Deseja mesmo apagar essa tarefa?",
      icon: 'warning',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, deletar!',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading({ id, loading: true });
        const response = await onDelete(id);
        if (response === true) {
          setRows(rows.filter((row) => row.id !== id));
          successToast('Tarefa apagada com sucesso!');
          setLoading({ id, loading: false });
        } else {
          setOpen(true);
          setMessage('Erro ao apagar tarefa.');
          setLoading({ id, loading: false });
        }
      }
    })
  };

  const handleCancelClick = (id: GridRowId) => () => {
    mode.current = 'view';
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  function CustomTypeEditComponent(props: GridEditSingleSelectCellProps) {
    const apiRef = useGridApiContext();

    const handleValueChange = async () => {
      await apiRef.current.setEditCellValue({
        id: props.id,
        field: 'conteudo',
        value: '',
      });

      setTimeout(() => {
        props.api.setCellFocus(props.id, 'conteudo');
      }, 100);
    };

    return <GridEditSingleSelectCell onValueChange={handleValueChange} {...props} />;
  }

  const handleVerifyEditMode = (params: any, e) => {
    if (mode.current === 'view') {
      mode.current = 'edit';
    }
  }

  const TextArea = ({ params }) => {
    const { value, api, id } = params;
    const [texto, setTexto] = React.useState(value?.texto || '');

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setTexto(event.target.value);
      api.setEditCellValue({ id, field: 'conteudo', value: { ...value, "texto": event.target.value } });
    }

    return (
      <TextField
        value={texto}
        onChange={handleTextChange}
        variant="outlined"
        multiline
        maxRows={5}
        style={{ width: "95%" }}
      />
    )
  }

  React.useEffect(() => {
    setRows(data);
  }, [data]);

  const UrlToHours = ({ params }: any) => {
    if (chapters && chapters.length > 0) {
      const name = chapters.find((chapter: any) => chapter.value === params.value[0])?.label;

      return (
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => navigateToUrl(`/tarefas/${params.id}`)}>
          <strong>{name}</strong>
        </div>
      )
    }
  }

  const contentCell = (params: any) => {
    const isEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
    const tipo = params?.row?.tipo_tarefa_id;

    if (isEditMode) {
      return (
        <div style={{ flexDirection: 'column', display: 'flex', width: "95%" }}>
          {tipo === 2 &&
            <div style={{ padding: 10, width: "100%", textAlign: "center" }}>
              <Typography style={styles.text}>Texto*</Typography>
              <TextArea params={params} />
            </div>
          }
          {[1, 2].includes(tipo) &&
            <>
              <div style={{ display: "flex", flexDirection: "column", padding: 10, width: "100%", textAlign: "center", height: 80 }}>
                <Typography style={styles.text}>Texto*</Typography>
                <TextArea params={params} />
                <Typography style={styles.text} sx={{ marginTop: 2 }}>Imagem*</Typography>
                <ImageUploader format='json' params={params} />
              </div>
            </>
          }
          {tipo === 3 &&
            <div style={{ padding: 10, width: "100%", textAlign: "center" }}>
              <Typography style={styles.text}>Áudio*</Typography>
              <AudioUploader format='json' params={params} />
            </div>
          }
        </div>
      );
    } else {
      return (
        <div style={{ flexDirection: 'row', display: 'flex', width: "95%", justifyContent: 'center', alignItems: "center", height: 50 }}>
          {params?.row?.conteudo?.audio && (
            <MusicVideo style={{ color: "#0008", marginRight: 10 }} />
          )}

          {params?.row?.conteudo?.imagem && (
            <AttachFile style={{ color: "#0008", marginRight: 10 }} />
          )}

          {params?.row?.conteudo?.texto && (
            <Chat style={{ color: "#0008", marginRight: 10 }} />
          )}
        </div>
      )
    }
  }

  const columns: GridColDef[] = [
    {
      field: 'capitulo_id',
      type: 'singleSelect',
      minWidth: 200,
      flex: 1,
      valueOptions: chapters,
      editable: true,
      renderHeader: () => <strong>Capítulo</strong>,
      renderCell: (params) => <UrlToHours params={params} />,
      renderEditCell(params) {
        const fieldParams = {
          ...params,
          items: chapters,
          column: 'capitulo_id',
          nome: 'label'
        };
        return <MultipleChapters params={fieldParams} />;
      },
    },
    {
      field: 'tipo_tarefa_id',
      minWidth: 80,
      flex: 1,
      type: 'singleSelect',
      headerAlign: 'center',
      align: 'center',
      valueOptions: types,
      editable: true,
      renderHeader: () => <strong>Tipo</strong>,
      renderEditCell: (params) => {
      if (params.value === 1) {
        setSizeRow(large);
      }
      if (params.value === 2) {
        setSizeRow(medium);
      }
      if (params.value === 3) {
        setSizeRow(small);
      }
        return <CustomTypeEditComponent {...params} />
      }
    },
    {
      field: 'texto',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      editable: false,
      valueParser: (value) => value?.texto,
      renderHeader: () => <strong>Texto</strong>,
      renderCell(params) {
        return (
          <div style={{ flexDirection: 'row', display: 'flex', width: "95%", justifyContent: 'center', alignItems: "center", height: 50 }}>
            {params?.row?.conteudo?.texto && (
              <>
                <Typography style={{ color: "#0008" }}>
                  {params?.row?.conteudo?.texto.length > 20
                    ? params?.row?.conteudo?.texto.slice(0, 20) + '...'
                    : params?.row?.conteudo?.texto
                  }
                </Typography>
              </>
            )}
          </div>
        )
      },
    },
    {
      field: 'conteudo',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      editable: true,
      renderHeader: () => <strong>Conteúdo</strong>,
      renderCell: contentCell,
      renderEditCell: contentCell
    },
    {
      field: 'criado_em',
      headerAlign: 'center',
      align: 'center',
      type: 'string',
      minWidth: 0,
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      renderHeader: () => <strong>Criado em</strong>,
    },
    {
      field: 'actions',
      type: 'actions',
      renderHeader: () => <strong>Ações</strong>,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<LaunchIcon />}
            label="Detalhes"
            onClick={handleDetailClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Editar"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Remover"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <Toast
        message={message}
        open={open}
        setclose={setOpen}
        severity="error"
      />

      <DataGrid
        rows={rows}
        columns={columns}
        disableColumnMenu
        getRowHeight={(params) => {
          if (mode.current === 'edit' || mode.current === 'new') {
            // const tipo = params.model.tipo_tarefa_id;
            const editMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;
            
            if (editMode) {
              // if (tipo === 2) {
              //   return sizeRow; // medium
              // }
              // if ([1, 2].includes(tipo)) {
              //   return sizeRow; // large
              // }
              // if (tipo === 3) {
              //   return sizeRow; // small
              // }
              return sizeRow;
            } else {
              return 40;
            }

            // if (params.model?.isNew === true) {
            //   return 170;
            // }
          }
        }}
        editMode="row"
        onRowDoubleClick={handleVerifyEditMode}
        columnVisibilityModel={{
          texto: mode.current === 'view',
          criado_em: mode.current === 'view',
        }}
        autosizeOptions={{
          columns: ['capitulo_id', 'tipo_tarefa_id', 'conteudo', 'criado_em'],
          includeOutliers: true,
          includeHeaders: true,
        }}
        localeText={{
          noRowsLabel: 'Nenhuma tarefa encontrada.',
          footerRowSelected: (count) => `${count} tarefa(s) selecionada(s)`,
        }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        onCellDoubleClick={(_, event) => {
          if (mode.current !== 'view') {
            event.defaultMuiPrevented = true;
          }
        }}
        onCellKeyDown={(p, e) => {
          e.defaultMuiPrevented = true;
        }}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar as unknown as GridSlots['toolbar'],
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel } as CustomGridToolbarProps,
          pagination: {
            labelRowsPerPage: "Tarefas por página",
            labelDisplayedRows: ({ from, to, count }) => `${from}-${to} de ${count}`,
          }
        }}
      />
    </Box>
  );
}