import React from "react";
import AccountMenu from "../AccountMenu";
import { Box, Typography } from "@mui/material";
import { SearchField } from "../../Components/Search";
import CrudVideos from "./components/EditableVideos";
import { SkeletonGrid } from "../../Components/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import VideosOperations from "../../InfraStructure/Videos/Operations";
import Toast from "../../Common/Helpers/Toast";
import Failure from "../../Components/Failure";
import { ResponseProps } from "./components/types";
import { AppDispatch } from "../../InfraStructure/store";

export default function Videos() {
  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [filteredData, setFilteredData] = React.useState<any[]>();

  const dispatch = useDispatch<AppDispatch>();
  const { IsLoading, HasError, Data } = useSelector(
    (state: any) => state.videos
  );

  const openToast = (message: string) => {
    setMessage(message);
    setOpen(true);
  }

  const getMovies = async () => {
    try {
      await dispatch(VideosOperations.getVideos());
    } catch (error: string | unknown) {
      console.log('Erro buscando videos: ', error);
      openToast('Erro na rede. Tente novamente...');
    }
  }

  React.useEffect(() => {
    getMovies();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (Data) {
      setFilteredData(Data);
    }
  }, [Data]);

  const handleSave = async (data: any): Promise<ResponseProps> => {
    const formData = new FormData();
    formData.append('nome', data.nome);
    formData.append('nivel_id', data.nivel_id);
    formData.append('url', data.url);
    formData.append('imagem', data.imagem);
    formData.append('capitulo_id', data.capitulo_id);
    formData.append('ativo', data.ativo ? '1' : '0');
    try {
      const response = await dispatch(VideosOperations.createVideo(formData));
      return response;
    } catch (error: string | unknown) {
      console.log('Erro salvando vídeo: ', error);
      return { status: 'error', message: 'Erro ao salvar o vídeo' };
    }
  }
  const handleUpdate = async (data: any): Promise<ResponseProps> => {
    const formData = new FormData();
    formData.append('id', data.id);
    if (data.nome) formData.append('nome', data.nome);
    if (data.nivel_id) formData.append('nivel_id', data.nivel_id);
    if (data.url) formData.append('url', data.url);
    if (data.imagem) formData.append('imagem', data.imagem);
    if (data.capitulo_id) formData.append('capitulo_id', data.capitulo_id);
    if (data.ativo !== undefined) formData.append('ativo', data.ativo);

    try {
      const response = await dispatch(VideosOperations.updateVideo(formData));
      return response;
    } catch (error: string | unknown) {
      console.log('Erro atualizando vídeo: ', error);
      return { status: 'error', message: 'Erro ao atualizar o vídeo' };
    }
  }

  const handleDelete = async (id: number): Promise<boolean> => {
    try {
      await dispatch(VideosOperations.deleteVideos(id));
      return true;
    } catch (error: string | unknown) {
      console.log('Erro deletando vídeo: ', error);
      return false;
    }
  }

  if (HasError) {
    return (
      <React.Fragment>
        <Toast message={message} open={open} setclose={setOpen} severity="error" />
        <Failure message='Erro ao buscar Vídeos' onClick={getMovies} />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <AccountMenu />

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'flex-start' }}>
        <Box sx={{ width: "95%", margin: 'auto', padding: 2 }}>
          <Typography
            variant="h5"
            sx={{ color: "#000", fontWeight: "bold", marginTop: 2, marginBottom: 2 }}>
            Vídeos
          </Typography>
          
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              marginBottom: 3,
            }}>
            <SearchField
              width={270}
              data={Data}
              setSearch={setFilteredData}
              columnsToSearch={["nome"]}
              placeHolder="Buscar por nome..."
            />
          </Box>
          
          {IsLoading
            ? <SkeletonGrid />
            : <CrudVideos
                data={filteredData}
                onSave={handleSave}
                onUpdate={handleUpdate}
                onDelete={handleDelete}
              />
          }
        </Box>
      </Box>
    </React.Fragment>
  );
}