import Api from "../Helpers/Api";

class VideosService {
  getVideos() {
    return Api.get(`/videos`);
  }

  createVideo(data: FormData) {
    return Api.post(`/videos`, data);
  }

  updateVideo(data: FormData) {
    return Api.post(`/videos/update`, data);
  }

  deleteVideo(id: number) {
    return Api.get(`/videos/delete/${id}`);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new VideosService();