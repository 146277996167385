import { UnknownAction } from "redux";
import AdministratorsActions from "../../InfraStructure/Administrators/Actions";
import AdministratorsService from "../../Common/Services/AdministratorsService";

interface Dispatch {
  (action: UnknownAction): void;
}

interface AdministratorsOperationsType {
  getAdministrators: () => (dispatch: Dispatch) => Promise<void>;
}

const AdministratorsOperations: AdministratorsOperationsType = {
  getAdministrators: () => async (dispatch: Dispatch): Promise<void> => {
    dispatch(AdministratorsActions.SetLoading());
    try {
      const response = await AdministratorsService.getAdministrators();
      dispatch(AdministratorsActions.SetSuccess(response.data));
    } catch (error: string | unknown) {
      console.log('Erro buscando administradores: ', error);
      dispatch(AdministratorsActions.SetFailure());
    }
  }
}

export default AdministratorsOperations;