import Api from "../Helpers/Api";

class SpeakingService {
  getSpeaking() {
    return Api.get(`/speaking`);
  }

  saveSpeaking(data: FormData) {
    return Api.post(`/speaking`, data);
  }

  updateSpeaking(data: FormData) {
    return Api.post(`/speaking/update`, data);
  }

  deleteSpeaking(id: number) {
    return Api.get(`/speaking/delete/${id}`);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new SpeakingService();