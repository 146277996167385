import React, { useEffect } from "react";
import AccountMenu from "../AccountMenu";
import { Box, Typography } from "@mui/material";
import { SearchField } from "../../Components/Search";
import CrudRooms from "./components/EditableRooms";
import { SkeletonGrid } from "../../Components/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import RoomsOperations from "../../InfraStructure/Rooms/RoomsOperations";
import Toast from "../../Common/Helpers/Toast";
import Failure from "../../Components/Failure";
import { ResponseProps } from "../../@types/types";

export default function Rooms() {
  const [data, setData] = React.useState<any[]>();
  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [filteredData, setFilteredData] = React.useState<any[]>();

  const dispatch = useDispatch();
  const { IsLoading, HasError, Data } = useSelector(
    (state: any) => state.rooms
  );

  const openToast = (message: string) => {
    setMessage(message);
    setOpen(true);
  };

  const getRooms = async () => {
    try {
      await dispatch<any>(RoomsOperations.getRooms());
    } catch (error: string | unknown) {
      console.log('Erro buscando salas: ', Object.entries(error));
      openToast('Erro na rede. Tente novamente...');
    }
  };

  useEffect(() => {
    getRooms();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Data) {
      setData(Data);
      setFilteredData(Data);
    }
  }, [Data]);

  const handleSave = async (data: any): Promise<ResponseProps> => {
    try {
      if (data.isNew) {
        const response = await dispatch<any>(RoomsOperations.saveRoom(data));
        return response;
      } else {
        const response = await dispatch<any>(RoomsOperations.updateRoom(data));
        return response;
      }
    } catch (error: string | unknown) {
      console.log('Erro salvando sala: ', Object.entries(error));
      openToast('Erro ao salvar sala. Tente novamente...');
    }
  };

  const handleDelete = async (id: number): Promise<boolean> => {
    try {
      await dispatch<any>(RoomsOperations.deleteRoom(id));
      return true;
    } catch (error: string | unknown) {
      console.log('Erro deletando sala: ', Object.entries(error));
      openToast('Erro ao deletar sala. Tente novamente...');
      return false;
    }
  }

  if (HasError) {
    return (
      <React.Fragment>
        <Toast message={message} open={open} setclose={setOpen} severity="error" />
        <Failure message='Erro ao buscar salas' onClick={getRooms} />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <AccountMenu />
      <Toast message={message} open={open} setclose={setOpen} severity="error" />

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'flex-start' }}>
        <Box sx={{ width: "95%", margin: 'auto', padding: 2 }}>
          <Typography
            variant="h5"
            sx={{ color: "#000", fontWeight: "bold", marginTop: 2, marginBottom: 2 }}>
            Salas
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              marginBottom: 3,
            }}>
            <SearchField
              width={380}
              placeHolder="Buscar por nome, número da sala, nível, professor..."
              data={data}
              columnsToSearch={['sala', 'nsala', 'nivel', 'escola']}
              setSearch={setFilteredData}
            />
          </Box>

          {IsLoading
            ? <SkeletonGrid />
            : <CrudRooms
                data={filteredData}
                onSave={handleSave}
                onDelete={handleDelete}
              />
          }
        </Box>
      </Box>
    </React.Fragment>
  );
}