import { UnknownAction } from "redux";
import ChaptersActions from "./Actions";
import ChaptersService from "../../Common/Services/ChaptersService";
import { ResponseProps } from "../../Page/Chapters/components/types";

interface Dispatch {
  (action: UnknownAction): void;
}

interface ChaptersOperationsType {
  getChapters: () => (dispatch: Dispatch) => Promise<void>;
  createChapters: (formData: FormData) => (dispatch: Dispatch) => Promise<ResponseProps>;
  updateChapters: (formData: FormData) => (dispatch: Dispatch) => Promise<ResponseProps>;
  deleteChapters: (id: number) => (dispatch: Dispatch) => Promise<ResponseProps>;
}

const ChaptersOperations: ChaptersOperationsType = {
  getChapters: () => async (dispatch: Dispatch): Promise<void> => {
    dispatch(ChaptersActions.SetLoading());

    try {
      const response = await ChaptersService.getChapters();
      const data = (response as { data: { data: any } }).data;
      dispatch(ChaptersActions.SetSuccess(data.data));
    } catch (error: string | unknown) {
      console.log('Erro buscando capítulos: ', error);
      dispatch(ChaptersActions.SetFailure());
    }
  },

  createChapters: (formData: FormData) => async (dispatch: Dispatch): Promise<ResponseProps> => {
    try {
      const response = await ChaptersService.createChapter(formData);
      return response.data as unknown as ResponseProps;
    } catch (error: string | unknown) {
      console.log('Erro salvando capítulo: ', error);
      throw new Error(typeof error === 'string' ? error : 'Unknown error');
    }
  },

  updateChapters: (formData: FormData) => async (dispatch: Dispatch): Promise<ResponseProps> => {
    try {
      const response = await ChaptersService.updateChapter(formData);
      return response.data as unknown as ResponseProps;
    } catch (error: string | unknown) {
      console.log('Erro atualizando capítulo: ', error);
      throw new Error(typeof error === 'string' ? error : 'Unknown error');
    }
  },

  deleteChapters: (id: number) => async (dispatch: Dispatch): Promise<ResponseProps> => {
    try {
      await ChaptersService.deleteChapter(id);
      return { message: 'Capítulo deletado com sucesso.', status: 'success' };
    } catch (error: string | unknown) {
      console.log('Erro deletando capítulo: ', error);
      throw new Error(typeof error === 'string' ? error : 'Unknown error');
    }
  }
}

export default ChaptersOperations;