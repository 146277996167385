import { UnknownAction } from "redux";
import SpeakingActions from "./Actions";
import SpeakingService from "../../Common/Services/SpeakingService";
import { ResponseProps } from "../../@types/types";

interface Dispatch {
  (action: UnknownAction): void;
}

interface SpeakingOperationsType {
  getSpeaking: () => (dispatch: Dispatch) => Promise<void>;
  saveSpeaking: (data: any) => () => Promise<ResponseProps>;
  updateSpeaking: (data: any) => () => Promise<ResponseProps>;
  deleteSpeaking: (id: number) => () => Promise<ResponseProps>;
}

const SpeakingOperations: SpeakingOperationsType = {
  getSpeaking: () => async (dispatch: Dispatch): Promise<void> => {
    dispatch(SpeakingActions.SetLoading());
    try {
      const response = await SpeakingService.getSpeaking();
      const data = (response as { data: { data: any } }).data;
      dispatch(SpeakingActions.SetSuccess(data.data));
    } catch (error: string | unknown) {
      console.log('Erro buscando tarefas: ', error);
      dispatch(SpeakingActions.SetFailure());
    }
  },

  saveSpeaking: (data: any) => async (): Promise<ResponseProps> => {
    try {
      const formData = new FormData();
      formData.append('titulo', data.titulo);
      formData.append('descricao', data.descricao);
      formData.append('data', data.data);
      formData.append('escola_id', data.escola_id);
      formData.append('link', data.link);
      formData.append('ativo', data.ativo);
      if (data?.sala_id) formData.append('sala_id', data.sala_id);

      const response = await SpeakingService.saveSpeaking(formData);
      return response.data as unknown as ResponseProps;
    } catch (error: string | unknown) {
      console.log('Erro salvando aula de speaking: ', error);
      return { message: 'Erro ao salvar aula de speaking', status: 'error' };
    }
  },

  updateSpeaking: (data: any) => async (): Promise<ResponseProps> => {
    try {
      const formData = new FormData();
      formData.append('id', data.id);
      if (data.titulo) formData.append('titulo', data.titulo);
      if (data.descricao) formData.append('descricao', data.descricao);
      if (data.data) formData.append('data', data.data);
      if (data.escola_id) formData.append('escola_id', data.escola_id);
      if (data.link) formData.append('link', data.link);
      if (data.link !== null) formData.append('ativo', data.ativo);
      if (data?.sala_id) formData.append('sala_id', data.sala_id);

      const response = await SpeakingService.updateSpeaking(formData);
      return response.data as unknown as ResponseProps;
    } catch (error: string | unknown) {
      console.log('Erro atualizando aula de speaking: ', error);
      return { message: 'Erro ao atualizar aula de speaking', status: 'error' };
    }
  },

  deleteSpeaking: (id: number) => async (): Promise<ResponseProps> => {
    try {
      const response = await SpeakingService.deleteSpeaking(id);
      return response.data as unknown as ResponseProps;
    } catch (error: string | unknown) {
      console.log('Erro deletando aula de speaking: ', error);
      return { message: 'Erro ao deletar aula de speaking', status: 'error' };
    }
  }
}

export default SpeakingOperations;