import Api from "../Helpers/Api";

class DocumentsService {
  getDocuments() {
    return Api.get(`/documentos`);
  }

  createDocument(formData: FormData) {
    return Api.post(`/documentos`, formData);
  }

  updateDocument(formData: FormData) {
    return Api.post(`/documentos/update`, formData);
  }

  deleteDocument(id: number) {
    return Api.get(`/documentos/delete/${id}`);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new DocumentsService();