import TeachersTypes from './Types';

const TeachersActions = {
  SetLoading: () => ({
    type: TeachersTypes.SET_TEACHERS_LOADING
  }),
  SetFailure: () => ({
    type: TeachersTypes.SET_TEACHERS_FAILURE
  }),
  SetSuccess: (payload: any) => ({
    type: TeachersTypes.SET_TEACHERS_SUCCESS,
    payload
  }),
}

export default TeachersActions;