import React from "react";
import AccountMenu from "../AccountMenu";
import { Box, Typography } from "@mui/material";
import CrudSettings from "./components/EditableSettings";
import Toast from "../../Common/Helpers/Toast";
import Failure from "../../Components/Failure";
import { SkeletonGrid } from "../../Components/Skeleton";
import SettingsOperations from "../../InfraStructure/Settings/Operations";
import { ResponseProps } from "../../@types/types";

export default function Settings() {
  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [data, setData] = React.useState<any[]>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);

  const openToast = (message: string) => {
    setMessage(message);
    setOpen(true);
  };

  const getSettings = async () => {
    setLoading(true);
    try {
      const response = await SettingsOperations.getSettings();
      setData(response.data);
      setError(false);
      setLoading(false);
    } catch (error: string | unknown) {
      console.log('Erro buscando configurações: ', error);
      openToast('Erro na rede. Tente novamente...');
      setError(true);
      setLoading(false);
    }
  };

  const handleUpdate = async (data: any): Promise<ResponseProps> => {
    const formData = new FormData();
    formData.append('id', data.id);
    formData.append('url_conheca', data.url_conheca);
    formData.append('url_tutorial', data.url_tutorial);

    try {
      const response = await SettingsOperations.updateSettings(formData);
      console.log('response: ', response);
      return response as unknown as ResponseProps;
    } catch (error: string | unknown) {
      console.log('Erro atualizando configurações: ', error);
      return { status: 'error', message: 'Erro ao atualizar as configurações' };
    }
  }

  React.useEffect(() => {
    getSettings();
    // eslint-disable-next-line
  }, []);

  if (error) {
    return (
      <React.Fragment>
        <Toast message={message} open={open} setclose={setOpen} severity="error" />
        <Failure message='Erro ao acessar as configurações.' onClick={getSettings} />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <AccountMenu />

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'flex-start' }}>
        <Box sx={{ width: "95%", margin: 'auto', padding: 2 }}>
          <Typography
            variant="h5"
            sx={{ color: "#000", fontWeight: "bold", marginTop: 2, marginBottom: 2 }}>
            Configurações
          </Typography>
          
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              marginBottom: 3,
            }}>
            {/* <SearchField width={270} placeHolder="Buscar por nome, e-mail" /> */}
          </Box>
          
          {loading
            ? <SkeletonGrid />
            : <CrudSettings data={data} onUpdate={handleUpdate}/>
          }
        </Box>
      </Box>
    </React.Fragment>
  );
}