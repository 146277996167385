import { UnknownAction } from "redux";
import TeachersActions from "./Actions";
import TeachersService from "../../Common/Services/TeachersService";
import { ResponseProps } from "../../@types/types";
import { crypto } from "../../Components/User";

interface Dispatch {
  (action: UnknownAction): void;
}

interface TeachersTypes {
  getTeachers: () => (dispatch: Dispatch) => Promise<void>;
  checkUser: (email: string) => Promise<ResponseProps>;
}

const TeachersOperations: TeachersTypes = {
  getTeachers: () => async (dispatch: Dispatch): Promise<void> => {
    dispatch(TeachersActions.SetLoading());
    try {
      const response = await TeachersService.getTeachers();
      const listTeachers = response.data as any[];
      let teacherOrderByName: any[] = [];
      
      if (listTeachers.length > 0) {
        teacherOrderByName = listTeachers.sort((a: any, b: any) => {
          if (a.nome < b.nome) {
            return -1;
          }
          if (a.nome > b.nome) {
            return 1;
          }
          return 0;
        });
      }

      dispatch(TeachersActions.SetSuccess(teacherOrderByName));
    } catch (error: string | unknown) {
      console.log('Erro buscando professores: ', error);
      dispatch(TeachersActions.SetFailure());
    }
  },

  checkUser: async (email: string): Promise<ResponseProps> => {
    try {
      const response = await TeachersService.checkUser(email);
      const data = response.data as {
        message: string;
        status: string;
        user: any;
        token: string;
        tokenB2C: string;
        access: boolean;
      };

      const userInfo = {
        user: data.user,
        token: data.token,
        tokenB2C: data.tokenB2C,
        access: data.access,
      }

      const userAccess = localStorage.getItem('userInfo');
      if (userAccess) {
        localStorage.removeItem('userInfo');
      }

      const userCrypto = crypto(JSON.stringify(userInfo));
      localStorage.setItem('userInfo', userCrypto);
      localStorage.setItem('userAccess', userAccess);

      return { status: 'success', message: 'Usuário modificado.' };
    } catch (error: string | unknown) {
      console.log('Erro verificando usuário: ', error);
      return { status: 'error', message: 'Erro de rede.' };
    }
  },
}

export default TeachersOperations;