import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
} from '@mui/x-data-grid';
import {
  randomId,
} from '@mui/x-data-grid-generator';
import { CustomGridToolbarProps, LevelProps, ResponseProps } from '../../../@types/types';
import { CircularProgress, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../InfraStructure/store';
import LevelsOperations from '../../../InfraStructure/Levels/LevelsOperations';
import ChaptersOperations from '../../../InfraStructure/Chapters/Operations';
import { SchoolChoice, SelectTeacher } from '../../../Components/Medias';
import { ChapterProps } from '../../Chapters/components/types';
import { SchoolListProps } from '../../Schools/components/types';
import SchoolOperations from '../../../InfraStructure/Schools/Operations';
import Toast from '../../../Common/Helpers/Toast';
import { successToast } from '../../../Common/Helpers/Utils';
import Swal from 'sweetalert2';
import { AccessAlarm } from '@mui/icons-material';
import { navigateToUrl } from 'single-spa';
import TeachersOperations from '../../../InfraStructure/Teachers/Operations';

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
}

type DataProps = {
  data: GridRowsProp;
  onSave: (data: any) => Promise<ResponseProps>;
  onDelete: (id: number) => Promise<boolean>;
}

export default function CrudRooms({ data, onSave, onDelete }: DataProps) {
  const [rows, setRows] = React.useState(data);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [mode, setMode] = React.useState<'view' | 'edit' | 'new'>('view');
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [loading,setLoading] = React.useState({ id: undefined, status: false });
  const [levels, setLevels] = React.useState<LevelProps[]>([]);
  // eslint-disable-next-line
  const [chapters, setChapters] = React.useState<ChapterProps[]>([]);
  const [schools, setSchools] = React.useState<SchoolListProps[]>([]);

  const dispatch = useDispatch<AppDispatch>();
  const {
    Data: DataChapter,
  } = useSelector((state: any) => state.chapters);
  const {
    Data: DataLevels,
  } = useSelector((state: any) => state.levels);
  const {
    Data: DataSchools,
    IsLoading: IsLoadingSchools,
  } = useSelector((state: any) => state.school);
  const {
    Data: DataTeachers,
    IsLoading: IsLoadingTeachers,
  } = useSelector((state: any) => state.teachers);

  function EditToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel } = props;
  
    const handleClick = () => {
      const id = randomId();
      setRows((oldRows) => [...oldRows, {
        id,
        sala: '',
        id_sala: '',
        num_sala: '',
        nivel_id: 0,
        nivel: '',
        escola_id: 0,
        usuario_id: '',
        criado_em: '',
        isNew: true,
      }]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'sala' },
      }));

      setMode('new');
    };
  
    return (
      <GridToolbarContainer>
        <Button
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
          disabled={loading.status || mode !== 'view'}
          onClick={handleClick}>
          Adicionar
        </Button>
      </GridToolbarContainer>
    );
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.escapeKeyDown) {
      setRowModesModel({
        ...rowModesModel,
        [params.id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
      setMode('view');
    }
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleValidationData = (data: any) => {
    if (!data.sala) return { isValid: false, message: 'Campo Sala obrigatório.' };
    if (!data.num_sala) return { isValid: false, message: 'Campo Número da Sala obrigatório.' };
    if (!data.nivel) return { isValid: false, message: 'Campo Nível obrigatório.' };
    if (!data.escola_id) return { isValid: false, message: 'Campo Escola obrigatório.' };
    if (!data.usuario_id) return { isValid: false, message: 'Campo Professor obrigatório.' };

    return { isValid: true, message: '' };
  }

  const handleEditClick = (id: GridRowId) => () => {
    if (mode === 'edit') {
      setMessage('Não é possível editar mais de um registro por vez.');
      setOpen(true);
      return;
    }
    setMode('edit');
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    Swal.fire({
      title: 'Atenção',
      text: "Deseja mesmo apagar esta Sala?",
      icon: 'warning',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, apagar!',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading({ id, status: true });
        const response = await onDelete(Number(id));
        if (response === true) {
          setRows(rows.filter((row) => row.id !== id));
          successToast('Sala apagada com sucesso!');
          setLoading({ id, status: false });
        } else {
          setOpen(true);
          setMessage('Erro ao apagar a Sala.');
          setLoading({ id, status: false });
        }
      }
    });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setMode('view');
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow: GridRowModel) => {

    const validation = handleValidationData(newRow);

    if (!validation.isValid) {
      setMessage(validation.message);
      setOpen(true);
      return;
    }

    setLoading({ id: newRow.id, status: true });

    const response = await onSave(newRow);

    if (response.status === 'success') {
      setMode('view');
      successToast(response.message);
      setRows(rows.map((row) => (row.id === newRow.id ? newRow : row)));
      setLoading({ id: undefined, status: false });

      const returnedRow = {
        ...newRow,
        isNew: false,
        criado_em: response.data.criado_em,
      };
      
      return returnedRow;
    } else {
      setMessage(response.message);
      setOpen(true);
      setLoading({ id: undefined, status: false });
    }
  };

  const handleLevels = async () => {
    await dispatch(LevelsOperations.getLevels());
  }

  const handleChapters = async () => {
    await dispatch(ChaptersOperations.getChapters());
  }

  const handleSchools = async () => {
    await dispatch(SchoolOperations.getSchools());
  }

  const handleTeachers = async () => {
    dispatch(TeachersOperations.getTeachers());
  }

  React.useEffect(() => {
    if (DataChapter && DataChapter.length > 0) {
      setChapters(DataChapter);
    }
    if (DataLevels && DataLevels.length > 0) {
      setLevels(DataLevels);
    }
    if (DataSchools && DataSchools.length > 0) {
      setSchools(DataSchools);
    }
  }, [DataChapter, DataLevels, DataSchools]);

  React.useEffect(() => {
    handleLevels();
    handleChapters();
    handleSchools();
    handleTeachers();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    setRows(data);
  }, [data]);

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const UrlToHours = ({ params }: any) => {
    return (
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => navigateToUrl(`/salas-horarios/${params.id}`)}>
        <strong>{params.value}</strong>
      </div>
    )
  }

  const handleVerifyEditMode = (params: any) => {
    if (mode === 'edit') {
      setMessage('Não é possível editar mais de um registro por vez.');
      setOpen(true);
      
      handleCancelClick(params.id)();
      setMode('edit');
      return;
    } else {
      setMode('edit');
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'sala',
      type: 'string',
      minWidth: 90,
      flex: 1,
      editable: true,
      headerAlign: 'center',
      renderHeader: () => <strong>Sala</strong>,
      renderCell: (params) => <UrlToHours params={params} />,
    },
    {
      field: 'id_sala',
      type: 'number',
      editable: false,
      renderHeader: () => <strong>Sala nº</strong>,
    },
    {
      field: 'num_sala',
      type: 'string',
      minWidth: 80,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      editable: true,
      renderHeader: () => <strong>Sala nº</strong>,
    },
    {
      field: 'escola_id',
      type: 'string',
      minWidth: 180,
      flex: 1,
      align: IsLoadingSchools ? 'center' : 'left',
      headerAlign: 'left',
      editable: true,
      renderHeader: () => <strong>Escola</strong>,
      renderCell: (params) => {
        if (IsLoadingSchools) {
          return <CircularProgress size={16} />
        } else {
          return schools.find((item: SchoolListProps) => item.id === params.value)?.nome;
        }
      },
      renderEditCell: (params) => {
        const data = {
          ...params,
          schools,
          field: 'escola_id',
        }
        return <SchoolChoice {...data} />
      },
    },
    {
      field: 'usuario_id',
      type: 'string',
      minWidth: 150,
      flex: 1,
      align: IsLoadingTeachers ? 'center' : 'left',
      headerAlign: 'center',
      editable: true,
      renderHeader: () => <strong>Professor</strong>,
      renderCell: (params) => {
        const data = {
          ...params,
          teachers: DataTeachers,
        }
        if (IsLoadingTeachers) {
          return <CircularProgress size={16} />
        } else {
          return <SelectTeacher params={data} />
        }
      },
      renderEditCell: (params) => {
        const data = {
          ...params,
          teachers: DataTeachers,
        }
        return <SelectTeacher params={data} />
      },
    },
    {
      field: 'nivel',
      minWidth: 120,
      flex: 1,
      editable: true,
      type: 'singleSelect',
      headerAlign: 'left',
      valueOptions: levels?.map((item: LevelProps) => {
        return { value: item.id, label: `${item.nivel} - ${item.aaRef}` }
      }),
      renderHeader: () => <strong>Curso</strong>,
      renderCell: (params) => {
        return levels.find((item: LevelProps) => item.id === Number(params.value))?.nivel;
      },
    },
    // {
    //   field: 'capitulo_id',
    //   minWidth: 120,
    //   flex: 1,
    //   editable: true,
    //   type: 'custom',
    //   headerAlign: 'center',
    //   renderHeader: () => <strong>Capítulos</strong>,
    //   renderEditCell: (params) => {
    //     const data = {
    //       ...params,
    //       chapters,
    //     }
    //     return <MultipleChapters params={data} setMultipleChapters={setMultipleChapters} />;
    //   },
    // },
    {
      field: 'criado_em',
      headerAlign: 'center',
      type: 'string',
      minWidth: 180,
      flex: 1,
      editable: true,
      renderHeader: () => <strong>Criado em</strong>,
    },
    {
      field: 'actions',
      type: 'actions',
      renderHeader: () => <strong>Ações</strong>,
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={(loading.id === id && loading.status) ? <CircularProgress size={16} /> : <SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
              disabled={loading.status}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              disabled={loading.status}
              color="inherit"
            />,
          ];
        }

        return [
          <Tooltip title="Horários da Sala" arrow>
            <GridActionsCellItem
              icon={<AccessAlarm />}
              label="Delete"
              onClick={() => navigateToUrl(`/salas-horarios/${id}`)}
              disabled={loading.status}
              color="inherit"
            />
          </Tooltip>,
          <Tooltip title="Editar" arrow>
            <GridActionsCellItem
              icon={(loading.id === id && loading.status) ? <CircularProgress size={16} /> : <EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              disabled={loading.status}
              color="inherit"
            />
          </Tooltip>,
          <Tooltip title="Excluir" arrow>
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              disabled={loading.status}
              color="inherit"
            />
          </Tooltip>,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <Toast
        message={message}
        open={open}
        setclose={setOpen}
        severity="error"
      />
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        disableColumnMenu
        onRowDoubleClick={handleVerifyEditMode}
        columnVisibilityModel={{
          criado_em: mode === 'view',
          capitulo_id: mode !== 'view',
          id_sala: false,
        }}
        localeText={{ noRowsLabel: 'Nenhuma sala encontrada.', footerRowSelected: (count) => `${count} sala(s) selecionada(s)` }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar as unknown as GridSlots['toolbar'],
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel } as CustomGridToolbarProps,
          pagination: {
            labelRowsPerPage: "Salas por página",
            labelDisplayedRows: ({ from, to, count }) => `${from}-${to} de ${count}`,
          }
        }}
      />
    </Box>
  );
}