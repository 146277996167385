import { UnknownAction } from "redux";
import RoomsService from "../../Common/Services/RoomsService";
import RoomsActions from "./Actions";
import { ResponseProps } from "../../@types/types";

interface Dispatch {
  (action: UnknownAction): void;
}

interface RoomsOperationsType {
  getRooms: () => (dispatch: Dispatch) => Promise<void>;
  getRoomsBySchool: (id: string) => Promise<ResponseProps>;
  saveRoom: (data: any) => () => Promise<ResponseProps>;
  saveHourRoom: (data: any) => () => Promise<ResponseProps>;
  updateRoom: (data: any) => () => Promise<ResponseProps>;
  updateHourRoom: (data: any) => () => Promise<ResponseProps>;
  deleteRoom: (id: number) => () => Promise<ResponseProps>;
  deleteHourRoom: (id: number) => () => Promise<ResponseProps>;
  getRoomHours: (id: number) => () => Promise<ResponseProps>;
}

const RoomsOperations: RoomsOperationsType = {
  getRooms: () => async (dispatch: Dispatch): Promise<void> => {
    dispatch(RoomsActions.SetLoading());

    try {
      const response = await RoomsService.getRooms();
      const data = (response as { data: { data: any } }).data;
      dispatch(RoomsActions.SetSuccess(data.data));
    } catch (error) {
      dispatch(RoomsActions.SetFailure());
      throw new Error(error);
    }
  },

  getRoomsBySchool: async (id: string): Promise<ResponseProps> => {
    try {
      const response = await RoomsService.getRoomsBySchool(id);
      return response.data as unknown as ResponseProps;
    } catch (error) {
      console.log('Erro buscando salas', error);
      return { message: 'Erro ao buscar salas', status: 'error' };
    }
  },

  saveRoom: (data: any) => async (): Promise<ResponseProps> => {
    const formData = new FormData();
    if (data.capitulo_id) formData.append('capitulo_id', data.capitulo_id);
    formData.append('sala', data.sala);
    formData.append('num_sala', data.num_sala);
    formData.append('usuario_id', data.usuario_id);
    formData.append('nivel', data.nivel);
    formData.append('escola_id', data.escola_id);

    try {
      const response = await RoomsService.saveRoom(formData);
      return response.data as unknown as ResponseProps;
    } catch (error) {
      console.log('Erro salvando salas', error);
      return { message: 'Erro ao salvar a sala', status: 'error' };
    }
  },

  saveHourRoom: (data: any) => async (): Promise<ResponseProps> => {
    const formData = new FormData();
    formData.append('id_sala', data.id_sala);
    formData.append('dia_semana', data.dia_semana);
    formData.append('horario', data.horario);

    try {
      const response = await RoomsService.saveHourRoom(formData);
      return response.data as unknown as ResponseProps;
    } catch (error) {
      console.log('Erro salvando horários', error);
      return { message: 'Erro ao salvar horários', status: 'error' };
    }
  },

  updateHourRoom: (data: any) => async (): Promise<ResponseProps> => {
    const formData = new FormData();
    formData.append('id', data.id);
    formData.append('id_sala', data.id_sala);
    formData.append('dia_semana', data.dia_semana);
    formData.append('horario', data.horario);

    try {
      const response = await RoomsService.updateHourRoom(formData);
      return response.data as unknown as ResponseProps;
    } catch (error) {
      console.log('Erro atualizando horários', error);
      return { message: 'Erro ao atualizar horários', status: 'error' };
    }
  },

  updateRoom: (data: any) => async (): Promise<ResponseProps> => {
    const formData = new FormData();
    formData.append('id', data.id);
    if (data.capitulo_id) formData.append('capitulo_id', data.capitulo_id);
    if (data?.sala) formData.append('sala', data.sala);
    if (data?.num_sala) formData.append('num_sala', data.num_sala);
    if (data?.usuario_id) formData.append('usuario_id', data.usuario_id);
    if (data?.nivel) formData.append('nivel', data.nivel);
    if (data?.escola_id) formData.append('escola_id', data.escola_id);

    try {
      const response = await RoomsService.updateRoom(formData);
      return response.data as unknown as ResponseProps;
    } catch (error) {
      console.log('Erro atualizando salas', error);
      return { message: 'Erro ao atualizar a sala', status: 'error' };
    }
  },

  deleteRoom: (id: number) => async (): Promise<ResponseProps> => {
    try {
      const response = await RoomsService.deleteRoom(id);
      return response.data as unknown as ResponseProps;
    } catch (error) {
      console.log('Erro deletando sala', error);
      return { message: 'Erro ao apagar a sala', status: 'error' };
    }
  },

  deleteHourRoom: (id: number) => async (): Promise<ResponseProps> => {
    try {
      const response = await RoomsService.deleteHourRoom(id);
      return response.data as unknown as ResponseProps;
    } catch (error) {
      console.log('Erro deletando horários', error);
      return { message: 'Erro ao apagar horários', status: 'error' };
    }
  },

  getRoomHours: (id: number) => async (): Promise<ResponseProps> => {
    try {
      const response = await RoomsService.getHours(id);
      return response.data as unknown as ResponseProps;
    } catch (error) {
      console.log('Erro buscando horários', error);
      return { message: 'Erro ao buscar horários', status: 'error' };
    }
  }
}

export default RoomsOperations;