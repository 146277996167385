import React, { useEffect } from "react";
import AccountMenu from "../AccountMenu";
import { Box, Typography } from "@mui/material";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
import { SearchField } from "../../Components/Search";
import { SkeletonGrid } from "../../Components/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../../Common/Helpers/Toast";
import Failure from "../../Components/Failure";
import ExercisesOperations from "../../InfraStructure/Exercises/ExercisesOperations";
import CrudExercises from "./components/EditableExercises";
import { useParams } from "react-router-dom";
import HomeworksOperations from "../../InfraStructure/Homeworks/HomeworksOperations";

export default function Exercises() {
  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [filteredData, setFilteredData] = React.useState<any[]>();
  const [types, setTypes] = React.useState<any[]>();

  const dispatch = useDispatch();
  const { IsLoading, HasError, Data } = useSelector(
    (state: any) => state.exercises
  );

  // const Homeworks = useSelector(
  //   (state: any) => state.homeworks
  // )?.Data;

  let { tarefa_id } = useParams();

  const openToast = (message: string) => {
    setMessage(message);
    setOpen(true);
  }

  const getExercises = async () => {
    try {
      await dispatch<any>(HomeworksOperations.getHomework(parseInt(tarefa_id)))
      await dispatch<any>(ExercisesOperations.getExercisesByHomework(tarefa_id));
      await dispatch<any>(ExercisesOperations.getTypes());
    } catch (error: string | unknown) {
      console.log('Erro buscando tarefas: ', error);
      openToast('Erro na rede. Tente novamente...');
    }
  };

  const handleDelete = async (id: number): Promise<boolean> => {
    try {
      await dispatch<any>(ExercisesOperations.deleteExercises(id));
      return true;
    } catch (error: string | unknown) {
      console.log('Erro deletando capítulo: ', error);
      return false;
    }
  }

  useEffect(() => {
    if (Data) {
      setFilteredData(Data?.exercises?.data);
      setTypes((Data?.types?.data || []).map((type)=>({value: type.id, label: type.tipo}))||[]);
    }
  }, [Data]);

  useEffect(() => {
    getExercises();
    // eslint-disable-next-line
  }, []);

  // const SearchSelect = (data: any) => {
  //   const [searchValue, setSearchValue] = React.useState<string>('');

  //   const handleChange = (event: SelectChangeEvent) => {
  //     setSearchValue(event.target.value as string);
  //   };

  //   return (
  //     <FormControl variant="outlined" sx={{ minWidth: 150 }}>
  //       <InputLabel id="searchSelect">Capítulo</InputLabel>
  //       <Select
  //         labelId="selectSelect"
  //         id="selectSelect"
  //         label="Capítulo"
  //         value={searchValue}
  //         onChange={handleChange}
  //       >
  //         <MenuItem value=""><em>Nenhum</em></MenuItem>
  //         {data.data.map((item: any, index: number) => (
  //           <MenuItem key={index} value={item}>{item}</MenuItem>
  //         ))}
  //       </Select>
  //     </FormControl>
  //   )
  // }

  if (HasError) {
    return (
      <React.Fragment>
        <Toast message={message} open={open} setclose={setOpen} severity="error" />
        <Failure message='Erro ao buscar tarefas' onClick={getExercises} />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <AccountMenu />

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'flex-start' }}>
        <Box sx={{ width: "95%", margin: 'auto', padding: 2 }}>
          <Typography
            variant="h5"
            sx={{ color: "#000", fontWeight: "bold", marginTop: 2, marginBottom: 2 }}>
            Exercícios
          </Typography>
          
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              marginBottom: 3,
            }}>
            <SearchField
              width={270}
              placeHolder="Buscar por capítulo ou tipo"
              columnsToSearch={['capitulo', 'tipo']}
              data={Data}
              setSearch={setFilteredData}
            />
            {/* {chapters && <SearchSelect data={chapters} />} */}
          </Box>
          
          {IsLoading ? <SkeletonGrid /> : <CrudExercises data={filteredData} types={types} onDelete={handleDelete} />}
        </Box>
      </Box>
    </React.Fragment>
  );
}