import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
} from '@mui/x-data-grid';
import {
  randomId,
} from '@mui/x-data-grid-generator';
import { CustomGridToolbarProps, ResponseProps } from '../../../@types/types';
import { successToast } from '../../../Common/Helpers/Utils';
import Swal from 'sweetalert2';
import { CircularProgress } from '@mui/material';
// import { navigateToUrl } from 'single-spa';
import Toast from '../../../Common/Helpers/Toast';
import RoomsOperations from '../../../InfraStructure/Rooms/RoomsOperations';
import { format } from 'date-fns';
import { Choice, SelectRoom } from '../../../Components/Medias';
import { userData } from '../../../Components/User';

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
}

type DataProps = {
  data: GridRowsProp;
  onSave: (data: any) => Promise<ResponseProps>;
  onDelete: (id: number) => Promise<boolean>;
}

export default function CrudSpeaking({ data, onSave, onDelete }: DataProps) {
  const [rows, setRows] = React.useState(data);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [mode, setMode] = React.useState<'view' | 'edit' | 'new'>('view');
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [loading,setLoading] = React.useState({ id: undefined, status: false });
  // const [escola_id, setEscolaId] = React.useState<number>(undefined);
  // const [sala_id, setSalaId] = React.useState<number>(undefined);
  // const [schools, setSchools] = React.useState<any[]>([]);
  const [rooms, setRooms] = React.useState<any[]>([]);
  const [active, setActive] = React.useState<boolean>(false);
  const { user } = userData();

  function EditToolbar(props: EditToolbarProps) {
    const { setRows, setRowModesModel } = props;
  
    const handleClick = () => {
      const id = randomId();
      setRows((oldRows) => [...oldRows, {
        id,
        titulo: '',
        descricao: '',
        link: '',
        ativo: true,
        data: '',
        isNew: true,
      }]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: 'titulo' },
      }));

      setMode('new');
    };
  
    return (
      <GridToolbarContainer>
        <Button
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
          disabled={loading.status || mode !== 'view'}
          onClick={handleClick}>
          Adicionar
        </Button>
      </GridToolbarContainer>
    );
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.escapeKeyDown) {
      setRowModesModel({
        ...rowModesModel,
        [params.id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
      setMode('view');
    }
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleValidationData = (data: any) => {
    if (!data.titulo || data.titulo === '') {
      return { status: 'error', message: 'Título é obrigatório' };
    }
    if (!data.descricao || data.descricao === '') {
      return { status: 'error', message: 'Descrição é obrigatória' };
    }
    if (!data.sala_id || data.sala_id === '') {
      return { status: 'error', message: 'Sala é obrigatória' };
    }
    if (!data.link || data.link === '') {
      return { status: 'error', message: 'Link é obrigatório' };
    }
    if (!data.data || data.data === '') {
      return { status: 'error', message: 'Data é obrigatória' };
    }
    return { status: 'success', message: '' };
  }

  const handleEditClick = (id: GridRowId) => () => {
    if (mode === 'edit') {
      setMessage('Não é possível editar mais de um registro por vez.');
      setOpen(true);
      return;
    }
    setMode('edit');
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    Swal.fire({
      title: 'Atenção',
      text: "Deseja mesmo apagar esta Aula?",
      icon: 'warning',
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, apagar!',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading({ id, status: true });
        const response = await onDelete(Number(id));
        if (response === true) {
          setRows(rows.filter((row) => row.id !== id));
          successToast('Aula apagada com sucesso!');
          setLoading({ id, status: false });
        } else {
          setOpen(true);
          setMessage('Erro ao apagar a Aula.');
          setLoading({ id, status: false });
        }
      }
    });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setMode('view');
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    const updatedRow = {
      ...newRow,
      sala_id: newRow.sala_id,
      data: newRow.data && format(new Date(newRow.data), 'yyyy-MM-dd HH:mm:ss'),
      escola_id: user.escola, // ex.: CL538 escola/cdInternet
      isNew: newRow.isNew,
      ativo: active,
    };

    if (newRow?.isNew  && newRow?.isNew === true) {
      const validation = handleValidationData(updatedRow);
      
      if (validation.status === 'error') {
        setOpen(true);
        setMessage(validation.message);
        return;
      }
    }

    setLoading({ id: newRow.id, status: true });

    const response = await onSave(updatedRow);
    if (response.status === 'success') {
      successToast('Aula salva com sucesso!');
      setLoading({ id: newRow.id, status: false });

      const returnedRow = {
        ...updatedRow,
        isNew: false,
        criado_em: response.data.criado_em,
      };

      setRows(rows.map((row) => (row.id === newRow.id ? returnedRow : row)));
      setMode('view');
      return returnedRow;
    } else {
      setOpen(true);
      setMessage(response.message);
      setLoading({ id: newRow.id, status: false });
    }
  };

  const handleRooms = async (codSchool: string) => {
    const response = await RoomsOperations.getRoomsBySchool(codSchool);
    if (response.status === 'success') {
      setRooms(response.data);
    }
  }

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleVerifyEditMode = (params: any) => {
    if (mode === 'edit') {
      setMessage('Não é possível editar mais de um registro por vez.');
      setOpen(true);
      
      handleCancelClick(params.id)();
      setMode('edit');
      return;
    } else {
      setMode('edit');
    }
  };

  React.useEffect(() => {
    setRows(data);
  }, [data]);

  React.useEffect(() => {
    handleRooms(user.escola);
    // eslint-disable-next-line
  }, []);

  // const UrlToResources = ({ params }: any) => {
  //   return (
  //     <div
  //       style={{ cursor: 'pointer' }}
  //       onClick={() => navigateToUrl(`/speaking-meet/${params.id}`)}>
  //       <strong>{params.value}</strong>
  //     </div>
  //   )
  // }

  const columns: GridColDef[] = [
    {
      field: 'titulo',
      type: 'string',
      minWidth: 220,
      flex: 1,
      editable: true,
      renderHeader: () => <strong>Título</strong>,
    },
    {
      field: 'descricao',
      type: 'string',
      minWidth: 220,
      flex: 1,
      editable: true,
      renderHeader: () => <strong>Descrição</strong>,
    },
    {
      field: 'sala_id',
      minWidth: 160,
      flex: 1,
      editable: true,
      type: 'string',
      headerAlign: 'left',
      renderHeader: () => <strong>Sala</strong>,
      renderCell: (params) => {
        const data = {
          ...params,
          rooms,
        };

        return <SelectRoom params={data} />;
      },
      renderEditCell: (params) => {
        const data = {
          ...params,
          rooms,
        };

        return <SelectRoom params={data} />;
      },
    },
    {
      field: 'link',
      minWidth: 160,
      flex: 1,
      editable: true,
      type: 'string',
      headerAlign: 'left',
      renderHeader: () => <strong>Link Externo</strong>,
    },
    {
      field: 'data',
      headerAlign: 'center',
      type: 'dateTime',
      minWidth: 100,
      flex: 1,
      editable: true,
      valueFormatter: (params) => {
        const dataOriginal: string = params;
        if (!dataOriginal) return '';
        const dataISO = (dataOriginal && typeof dataOriginal === 'string') 
          ? new Date(dataOriginal.replace(" ", "T")).toISOString()
          : new Date(dataOriginal).toISOString();
        return dataISO.split('T')[0];
      },
      renderHeader: () => <strong>Data</strong>,
    },
    {
      field: 'ativo',
      headerAlign: 'center',
      type: 'boolean',
      minWidth: 70,
      flex: 1,
      editable: true,
      renderHeader: () => <strong>Ativo</strong>,
      renderCell: (params) => {
        return params.value ? 'Sim' : 'Não';
      },
      renderEditCell: (params) => {
        const data = {
          ...params,
          height: 76,
        }
        return <Choice params={data} setActive={setActive} />
      },
    },
    {
      field: 'criado_em',
      headerAlign: 'center',
      type: 'string',
      minWidth: 100,
      flex: 1,
      editable: false,
      renderHeader: () => <strong>Criada em</strong>,
    },
    {
      field: 'actions',
      type: 'actions',
      renderHeader: () => <strong>Ações</strong>,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={(loading.id === id && loading.status) ? <CircularProgress size={16} /> : <SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
              disabled={loading.status}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              disabled={loading.status}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={(loading.id === id && loading.status) ? <CircularProgress size={16} /> : <EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            disabled={loading.status}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            disabled={loading.status}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <Toast
        message={message}
        open={open}
        setclose={setOpen}
        severity="error"
      />
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        disableColumnMenu
        onRowDoubleClick={handleVerifyEditMode}
        columnVisibilityModel={{
          criado_em: mode === 'view',
          descricao: mode !== 'view',
          link: mode !== 'view',
          ativo: mode !== 'view',
        }}
        autosizeOptions={{
          columns: ['titulo', 'sala', 'data', 'criado_em'],
          includeOutliers: true,
          includeHeaders: true,
        }}
        localeText={{
          noRowsLabel: 'Nenhuma aula de speaking encontrada.',
          footerRowSelected: (count) => `${count} aula de speaking(s) selecionada(s)`,
        }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar as unknown as GridSlots['toolbar'],
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel } as CustomGridToolbarProps,
          pagination: {
            labelRowsPerPage: "Aulas por página",
            labelDisplayedRows: ({ from, to, count }) => `${from}-${to} de ${count}`,
          }
        }}
      />
    </Box>
  );
}