import { formatRelative, Locale } from "date-fns";
import { ptBR } from "date-fns/locale";
import Swal from "sweetalert2";

export const telephoneValidator = (value: string) => {
  /* Only numbers and 10 or 11 digits */
  if (!value.match(/^\d{10,11}$/)) {
    return false;
  }
  return true;
}

export const relativeDate = (date: string) => {
  /* Convert "2024-06-12 12:02:00" to "Hoje às 12:02" */
  const relative =
    formatRelative(
      new Date(date), new Date(),
      { locale: ptBR as unknown as Locale });
  const firstUpcase = relative.charAt(0).toUpperCase() + relative.slice(1);
  return firstUpcase;
}

export const successToast = (message: string) => {
  /* Show a success toast */
  return Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  }).fire({
    icon: 'success',
    title: message,
  });
}