import { combineReducers } from "@reduxjs/toolkit";

import school from '../InfraStructure/Schools/Reducer';
import rooms from '../InfraStructure/Rooms/Reducer';
import chapters from '../InfraStructure/Chapters/Reducer';
import homeworks from '../InfraStructure/Homeworks/Reducer';
import exercises from '../InfraStructure/Exercises/Reducer';
import speaking from '../InfraStructure/Speaking/Reducer';
import flashCards from '../InfraStructure/FlashCards/Reducer';
import videos from '../InfraStructure/Videos/Reducer';
import books from '../InfraStructure/Books/Reducer';
import games from '../InfraStructure/Games/Reducer';
import administrators from "../InfraStructure/Administrators/Reducer";
import coordinators from "../InfraStructure/Coordinators/Reducer";
import teachers from "../InfraStructure/Teachers/Reducer";
import students from "../InfraStructure/Students/Reducer";
import levels from "../InfraStructure/Levels/Reducer";
import user from "../InfraStructure/User/Reducer";
import questions from "../InfraStructure/Questions/Reducer";

export default combineReducers({
  school,
  rooms,
  chapters,
  homeworks,
  exercises,
  speaking,
  flashCards,
  videos,
  books,
  games,
  administrators,
  coordinators,
  teachers,
  students,
  levels,
  user,
  questions,
});