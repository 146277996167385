import Api from "../Helpers/Api";

class FlashCardsService {
  getFlashCards() {
    return Api.get(`/flashcards`);
  }

  createFlashCard(data: any) {
    return Api.post(`/flashcards`, data);
  }

  updateFlashCard(data: any) {
    return Api.post(`/flashcards/update`, data);
  }

  deleteFlashCard(id: number) {
    return Api.get(`/flashcards/delete/${id}`);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new FlashCardsService();