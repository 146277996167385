import React, { useEffect } from "react";
import AccountMenu from "../AccountMenu";
import { Box, Typography } from "@mui/material";
import { SchoolCRUDGrid } from "./components/EditableSchools";
import { SearchField } from "../../Components/Search";
import { SkeletonGrid } from "../../Components/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import SchoolOperations from "../../InfraStructure/Schools/Operations";
import Toast from "../../Common/Helpers/Toast";
import Failure from "../../Components/Failure";
import { formatISO } from "date-fns";

export default function Schools() {
  const [data, setData] = React.useState<any[]>();
  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [filteredData, setFilteredData] = React.useState<any[]>();
  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();
  const { IsLoading, HasError, Data } = useSelector(
    (state: any) => state.school
  );

  const openToast = (message: string) => {
    setMessage(message);
    setOpen(true);
  }

  const getSchools = async () => {
    try {
      await dispatch<any>(SchoolOperations.getSchools());
    } catch (error: string | unknown) {
      console.log('Erro buscando escolas: ', error);
      openToast('Erro na rede. Tente novamente...');
    }
  }

  useEffect(() => {
    getSchools();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Data) {
      setData(Data);
      setFilteredData(Data);
    }
  }, [Data]);

  const handleSave = async (data: any): Promise<boolean> => {
    const created_at = formatISO(new Date());
    data = { ...data, created_at };

    try {
      setLoading(true);
      await dispatch<any>(SchoolOperations.saveSchool(data));
      return true;
    } catch (error: string | unknown) {
      setLoading(false);
      console.log('Erro salvando escola: ', error);
      openToast('Erro ao salvar escola. Tente novamente...');
      return false;
    }
  };

  const handleUpdate = async (data: any): Promise<boolean> => {
    setLoading(true);

    try {
      await dispatch<any>(SchoolOperations.editSchool(data));
      setLoading(false);
      return true;
    } catch (error: string | unknown) {
      setLoading(false);
      console.log('Erro editando escola: ', error);
      openToast('Erro ao editar escola. Tente novamente...');
      return false;
    }
  };

  const handleDelete = async (id: number): Promise<boolean> => {
    setLoading(true);
    try {
      await dispatch<any>(SchoolOperations.deleteSchool(id));
      setLoading(false);
      return true;
    } catch (error: string | unknown) {
      setLoading(false);
      console.log('Erro deletando escola: ', error);
      openToast('Erro ao deletar escola. Tente novamente...');
      return false;
    }
  }

  if (HasError) {
    return (
      <React.Fragment>
        <Toast message={message} open={open} setclose={setOpen} severity="error" />
        <Failure message='Erro ao buscar escolas' onClick={getSchools} />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <AccountMenu />
      <Toast message={message} open={open} setclose={setOpen} severity="error" />

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'flex-start' }}>
        <Box sx={{ width: "95%", margin: 'auto', padding: 2 }}>
          <Typography variant="h5" sx={{ color: "#000", fontWeight: "bold", marginTop: 2, marginBottom: 2 }}>Unidades Escolares</Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', marginBottom: 3 }}>
            <SearchField placeHolder="Buscar por nome" data={data} setSearch={setFilteredData} />
          </Box>

          {IsLoading
            ? <SkeletonGrid />
            : <SchoolCRUDGrid
                data={filteredData}
                onSave={handleSave}
                onUpdate={handleUpdate}
                onDelete={handleDelete}
                loading={loading}
              />
          }
        </Box>
      </Box>
    </React.Fragment>
  );
}