import React from 'react';
import { Provider } from 'react-redux';
import ApplicationRoute from './Navigation/route';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import store from './InfraStructure/store';
import RouteLogin from './Navigation/routeLogin';
import { userData } from './Components/User';

const App: React.FC = () => {
  const theme = useTheme();
  const userInfo = userData();

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        {!userInfo
          ? <RouteLogin />
          : <ApplicationRoute />
        }
      </ThemeProvider>
    </Provider>
  );
};

export default App;