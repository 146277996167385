import { Dispatch } from "redux";
import GamesActions from "./Actions";
import GamesService from "../../Common/Services/GamesService";
import { ResponseProps } from "../../@types/types";

interface GamesOperationsType {
  getGames: () => (dispatch: Dispatch) => Promise<void>;
  createGames: (data: any) => (dispatch: Dispatch) => Promise<ResponseProps>;
  deleteGame: (id: number) => (dispatch: Dispatch) => Promise<ResponseProps>;
  updateGame: (data: any) => (dispatch: Dispatch) => Promise<ResponseProps>;
}

const GamesOperations: GamesOperationsType = {
  getGames: () => async (dispatch: Dispatch): Promise<void> => {
    dispatch(GamesActions.SetLoading());
    try {
      const response = await GamesService.getGames();
      const data = (response as { data: { data: any } }).data;
      dispatch(GamesActions.SetSuccess(data.data));
    } catch (error: string | unknown) {
      console.log('Erro buscando jogos: ', error);
      dispatch(GamesActions.SetFailure());
    }
  },

  createGames: (data: FormData) => async (dispatch: Dispatch): Promise<ResponseProps> => {
    try {
      const response = await GamesService.createGames(data);
      return response.data as unknown as ResponseProps;
    } catch (error: string | unknown) {
      console.log('Erro ao salvar jogos: ', error);
      throw new Error(typeof error === 'string' ? error : 'Unknown error');
    }
  },

  deleteGame: (id: number) => async (dispatch: Dispatch): Promise<ResponseProps> => {
    try {
      await GamesService.deleteGame(id);
      return { message: 'Jogo deletado com sucesso.', status: 'success' };
    } catch (error: string | unknown) {
      console.log('Erro deletando jogo: ', error);
      throw new Error(typeof error === 'string' ? error : 'Unknown error');
    }
  },

  updateGame: (data: FormData) => async (dispatch: Dispatch): Promise<ResponseProps> => {
    try {
      const response = await GamesService.updateGame(data);
      return response.data as unknown as ResponseProps;
    } catch (error: string | unknown) {
      console.log('Erro ao salvar jogos: ', error);
      throw new Error(typeof error === 'string' ? error : 'Unknown error');
    }
  }
}

export default GamesOperations;