import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridSlots,
} from '@mui/x-data-grid';
import {
  randomId,
} from '@mui/x-data-grid-generator';
import { CustomGridToolbarProps } from '../../../@types/types';

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
  ) => void;
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [...oldRows, {
      id,
      nome: '',
      email: '',
      escola: '',
      created_at: new Date().toLocaleDateString(),
      isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'nome' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button
        color="primary"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleClick}>
        Adicionar
      </Button>
    </GridToolbarContainer>
  );
}

type DataProps = {
  data: GridRowsProp;
}

export default function CrudCoordinators({ data }: DataProps) {
  const [rows, setRows] = React.useState(data);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  React.useEffect(() => {
    setRows(data);
  }, [data]);

  const columns: GridColDef[] = [
    {
      field: 'nome',
      minWidth: 180,
      flex: 1,
      editable: true,
      type: 'string',
      headerAlign: 'left',
      renderHeader: () => <strong>Nome</strong>,
    },
    {
      field: 'email',
      minWidth: 280,
      flex: 1,
      editable: true,
      type: 'string',
      headerAlign: 'left',
      renderHeader: () => <strong>E-mail</strong>,
    },
    {
      field: 'escola',
      minWidth: 250,
      flex: 1,
      editable: true,
      type: 'string',
      headerAlign: 'left',
      renderHeader: () => <strong>Escola</strong>,
    },
    {
      field: 'created_at',
      headerAlign: 'center',
      type: 'string',
      minWidth: 180,
      flex: 1,
      editable: true,
      renderHeader: () => <strong>Criado em</strong>,
    },
    {
      field: 'actions',
      type: 'actions',
      renderHeader: () => <strong>Ações</strong>,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
        // scrollbarWidth: 'none',
        // msOverflowStyle: 'none',
        // '&::-webkit-scrollbar': {
        //   display: 'none',
        // },
        // scrollbarColor: 'transparent transparent',
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        disableColumnMenu
        autosizeOptions={{
          columns: ['nome', 'email', 'escola', 'created_at'],
          includeOutliers: true,
          includeHeaders: true,
        }}
        localeText={{
          noRowsLabel: 'Nenhum coordenador cadastrado ainda.',
          footerRowSelected: (count) => `${count} usuário(s) selecionado(s)`,
        }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar as unknown as GridSlots['toolbar'],
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel } as CustomGridToolbarProps,
          pagination: {
            labelRowsPerPage: "Usuários por página",
            labelDisplayedRows: ({ from, to, count }) => `${from}-${to} de ${count}`,
          }
        }}
      />
    </Box>
  );
}