// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dF{
  display: flex;
}

.col{ 
  display: flex;
  flex-direction: column;
  flex:1 1;
}

.row{ 
  display: flex;
  flex-direction: row;
  flex:1 1;
}

#container{
  box-shadow: 1px 1px 3px 0px rgba(170,170,170,1);
  border-radius: 5px;
  padding: 30px;
  max-width: 1500px;
}

strong{
  color: #666;
  font-size: 16px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.flex2{
  flex:2 1
}

.flex1{
  flex:1 1
}

.txtQuestion{
  margin:5px !important;
}

.end{
  justify-content: flex-end;
}
`, "",{"version":3,"sources":["webpack://./src/Page/ExercisesEdit/components/FormExercises.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAM;AACR;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAM;AACR;;AAEA;EACE,+CAA+C;EAC/C,kBAAkB;EAClB,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,uDAAuD;AACzD;;AAEA;EACE;AACF;;AAEA;EACE;AACF;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".dF{\r\n  display: flex;\r\n}\r\n\r\n.col{ \r\n  display: flex;\r\n  flex-direction: column;\r\n  flex:1;\r\n}\r\n\r\n.row{ \r\n  display: flex;\r\n  flex-direction: row;\r\n  flex:1;\r\n}\r\n\r\n#container{\r\n  box-shadow: 1px 1px 3px 0px rgba(170,170,170,1);\r\n  border-radius: 5px;\r\n  padding: 30px;\r\n  max-width: 1500px;\r\n}\r\n\r\nstrong{\r\n  color: #666;\r\n  font-size: 16px;\r\n  font-family: \"Roboto\", \"Helvetica\", \"Arial\", sans-serif;\r\n}\r\n\r\n.flex2{\r\n  flex:2\r\n}\r\n\r\n.flex1{\r\n  flex:1\r\n}\r\n\r\n.txtQuestion{\r\n  margin:5px !important;\r\n}\r\n\r\n.end{\r\n  justify-content: flex-end;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
