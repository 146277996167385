import UserTypes from './Types';

const UserActions = {
  SetLoading: () => ({
    type: UserTypes.SET_USER_LOADING
  }),
  SetFailure: () => ({
    type: UserTypes.SET_USER_FAILURE
  }),
  SetSuccess: (payload: any) => ({
    type: UserTypes.SET_USER_SUCCESS,
    payload
  }),
}

export default UserActions;