import { ResponseProps } from "../../@types/types";
import SettingsService from "../../Common/Services/SettingsService";

interface SettingsOperationsType {
  getSettings: () => Promise<ResponseProps>;
  updateSettings: (formData: FormData) => void;
}

const SettingsOperations: SettingsOperationsType = {
  getSettings: async () => {
    try {
      const response = await SettingsService.getSettings();
      const data = (response as { data: { data: any } }).data;
      return { status: 'success', message: 'Configurações ok', data: data.data };
    } catch (error: string | unknown) {
      console.log('Erro buscando configurações: ', error);
      return { status: 'error', message: 'Erro ao buscar as configurações' };
    }
  },

  updateSettings: async (formData: FormData) => {
    try {
      await SettingsService.updateSettings(formData);
      return { status: 'success', message: 'Configurações atualizadas com sucesso' };
    } catch (error: string | unknown) {
      console.log('Erro atualizando configurações: ', error);
      return { status: 'error', message: 'Erro ao atualizar as configurações' };
    }
  },
};

export default SettingsOperations;