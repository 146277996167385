import { Navigate } from "react-router-dom";
import { decrypt } from "../Components/User";

const ProtectedRoute = ({ Component }) => {
  const userCrypto = localStorage.getItem('userInfo');
  const userData = decrypt(userCrypto);
  const userInfo = userData ? JSON.parse(userData) : null;

  const isAuthenticated = userInfo.token ? true : false;

  return isAuthenticated ? <Component /> : <Navigate to="/login" />;
}

export default ProtectedRoute;