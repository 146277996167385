import Api from "../Helpers/Api";

class LoginService {
  verifyHash(token: any) {
    return Api.get(`/verifytoken/${token}`);
  }

  verifySSOUser(token: any) {
    return Api.get(`/verifySSOuser/${token}`);
  }

  login(token: any) {
    return Api.get(`/loginWeb/${token}`);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new LoginService();