import Api from "../Helpers/Api";

class ExercisesService {
  getExercisesByHomework(tarefa_id) {
    return Api.get(`/exercicios/get_by_homework/${tarefa_id}`);
  }
  getExercise(id) {
    return Api.get(`/exercicios/get/${id}`);
  }
  getTypes() {
    return Api.get('/exercicios/get_types');
  }
  getTypesByHomework(tipo_tarefa_id) {
    return Api.get(`/exercicios/get_types_by_homework/${tipo_tarefa_id}`);
  }
  updateExercises(data){
    return Api.post(`/exercicios/update`, data);
  }
  createExercises(data){
    return Api.post(`/exercicios/store`, data);
  }
  deleteExercises(id: number) {
    return Api.get(`/exercicios/delete/${id}`);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ExercisesService();