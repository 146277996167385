import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import * as Modules from './modules';

const RouteLogin = ({ history, match, selectedPortal }: any) => {
  return (
    <Router>
      <Routes>
        <Route path="/:token" Component={Modules.Login} />
        <Route path="/logout" Component={Modules.Logout} />
        <Route path="*" Component={Modules.Login} />
      </Routes>
    </Router>
  );
};

export default RouteLogin;
