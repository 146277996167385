import React from "react";
import AccountMenu from "../AccountMenu";
import { Box, Typography } from "@mui/material";
import { SearchField } from "../../Components/Search";
import CrudFlashCards from "./components/EditableFlashCards";
import { SkeletonGrid } from "../../Components/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import FlashCardsOperations from "../../InfraStructure/FlashCards/FlashCardsOperations";
import Toast from "../../Common/Helpers/Toast";
import Failure from "../../Components/Failure";
import { ResponseProps } from "../../@types/types";

export default function FlashCards() {
  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [filteredData, setFilteredData] = React.useState<any[]>();

  const dispatch = useDispatch();
  const { IsLoading, HasError, Data } = useSelector(
    (state: any) => state.flashCards
  );

  const openToast = (message: string) => {
    setMessage(message);
    setOpen(true);
  }

  const getFlashCards = async () => {
    try {
      await dispatch<any>(FlashCardsOperations.getFlashCards());
    } catch (error: string | unknown) {
      console.log('Erro buscando flashCards: ', error);
      openToast('Erro na rede. Tente novamente...');
    }
  };

  React.useEffect(() => {
    if (Data) {
      setFilteredData(Data);
    }
  }, [Data]);

  const handleSave = async (data: any): Promise<ResponseProps> => {
    const formData = new FormData();
    formData.append('titulo', data.titulo);
    formData.append('nivel_id', data.nivel_id);
    formData.append('imagem', data.imagem);
    formData.append('audio', data.audio);
    formData.append('ativo', data.ativo);

    try {
      const response = await dispatch<any>(FlashCardsOperations.createFlashCard(formData));
      return response;
    } catch (error: string | unknown) {
      console.log('Erro salvando flashCard: ', error);
      return { message: 'Erro na rede. Tente novamente...', status: 'error' };
    }
  };

  const handleUpdate = async (data: any): Promise<ResponseProps> => {
    
    const formData = new FormData();
    formData.append('id', data.id);
    if (data?.nivel_id) formData.append('nivel_id', data.nivel_id);
    if (data?.ativo === 0 || data?.ativo === 1) formData.append('ativo', data.ativo);
    if (data?.titulo) formData.append('titulo', data.titulo);
    if (data?.imagem) formData.append('imagem', data.imagem);
    if (data?.audio) formData.append('audio', data.audio);

    try {
      const response = await dispatch<any>(FlashCardsOperations.updateFlashCard(formData));
      return response;
    } catch (error: string | unknown) {
      console.log('Erro atualizando flashCard: ', error);
      openToast('Erro na rede. Tente novamente...');
      return { message: 'Erro na rede. Tente novamente...', status: 'error' };
    }
  };

  const handleDelete = async (id: number): Promise<boolean> => {
    try {
      await dispatch<any>(FlashCardsOperations.deleteFlashCard(id));
      return true;
    } catch (error: string | unknown) {
      console.log('Erro deletando flashCard: ', error);
      openToast('Erro na rede. Tente novamente...');
      return false;
    }
  };

  React.useEffect(() => {
    getFlashCards();
    // eslint-disable-next-line
  }, []);

  if (HasError) {
    return (
      <React.Fragment>
        <Toast message={message} open={open} setclose={setOpen} severity="error" />
        <Failure message='Erro ao buscar tarefas' onClick={getFlashCards} />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <AccountMenu />

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'flex-start' }}>
        <Box sx={{ width: "95%", margin: 'auto', padding: 2 }}>
          <Typography
            variant="h5"
            sx={{ color: "#000", fontWeight: "bold", marginTop: 2, marginBottom: 2 }}>
            FlashCards
          </Typography>
          
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              marginBottom: 3,
            }}>
            <SearchField
              width={270}
              placeHolder="Buscar por título"
              data={Data}
              setSearch={setFilteredData}
              columnsToSearch={['titulo']}
            />
          </Box>
          
          {IsLoading
            ? <SkeletonGrid />
            : <CrudFlashCards
                data={filteredData}
                onSave={handleSave}
                onDelete={handleDelete}
                onUpdate={handleUpdate}
              />
          }
        </Box>
      </Box>
    </React.Fragment>
  );
}