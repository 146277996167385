import React from "react";
import AccountMenu from "../AccountMenu";
import { Box, Typography } from "@mui/material";
import { SearchField } from "../../Components/Search";
import CrudSpeaking from "./components/EditableSpeaking";
import { SkeletonGrid } from "../../Components/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import SpeakingOperations from "../../InfraStructure/Speaking/SpeakingOperations";
import Toast from "../../Common/Helpers/Toast";
import Failure from "../../Components/Failure";
import { ResponseProps } from "../../@types/types";

export default function Speaking() {
  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [filteredData, setFilteredData] = React.useState<any[]>();

  const dispatch = useDispatch();
  const { IsLoading, HasError, Data } = useSelector(
    (state: any) => state.speaking
  );

  const openToast = (message: string) => {
    setMessage(message);
    setOpen(true);
  }

  const getSpeakings = async () => {
    try {
      await dispatch<any>(SpeakingOperations.getSpeaking());
    } catch (error: string | unknown) {
      console.log('Erro buscando aulas de speaking: ', error);
      openToast('Erro na rede. Tente novamente...');
    }
  };

  React.useEffect(() => {
    if (Data) {
      setFilteredData(Data);
    }
  }, [Data]);

  React.useEffect(() => {
    getSpeakings();
    // eslint-disable-next-line
  }, []);

  const handleSave = async (data: any): Promise<ResponseProps> => {
    try {
      if (data.isNew) {
        const response = await dispatch<any>(SpeakingOperations.saveSpeaking(data));
        return response;
      } else {
        const response = await dispatch<any>(SpeakingOperations.updateSpeaking(data));
        return response;
      }
    } catch (error: string | unknown) {
      console.log('Erro salvando aula de speaking: ', error);
      openToast('Erro na rede. Tente novamente...');
      return { status: 'error', message: 'Erro na rede. Tente novamente...' };
    }
  };

  const handleDelete = async (id: number): Promise<boolean> => {
    try {
      await dispatch<any>(SpeakingOperations.deleteSpeaking(id));
      return true;
    } catch (error: string | unknown) {
      console.log('Erro deletando aula de speaking: ', error);
      openToast('Erro na rede. Tente novamente...');
      return false;
    }
  };

  if (HasError) {
    return (
      <React.Fragment>
        <Toast message={message} open={open} setclose={setOpen} severity="error" />
        <Failure message="Erro ao buscar aulas de speaking" onClick={getSpeakings} />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <AccountMenu />

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'flex-start' }}>
        <Box sx={{ width: "95%", margin: 'auto', padding: 2 }}>
          <Typography
            variant="h5"
            sx={{ color: "#000", fontWeight: "bold", marginTop: 2, marginBottom: 2 }}>
            Aulas de Speaking
          </Typography>
          
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              marginBottom: 3,
            }}>
            <SearchField
              width={270}
              placeHolder="Buscar por título ou sala"
              data={Data}
              setSearch={setFilteredData}
              columnsToSearch={['titulo', 'sala']}
            />
          </Box>
          
          {IsLoading
            ? <SkeletonGrid />
            : <CrudSpeaking
                data={filteredData}
                onSave={handleSave}
                onDelete={handleDelete}
              />
          }
        </Box>
      </Box>
    </React.Fragment>
  );
}