import ExercisesTypes from "./Types";

const INITIAL_STATE = {
  Data: {},
  IsLoading: true,
  HasError: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case ExercisesTypes.SET_EXERCISES_LOADING:
      return { ...state, IsLoading: true, HasError: false };
    case ExercisesTypes.SET_EXERCISES_SUCCESS:
      return { Data: {...state.Data, ...action.payload }, IsLoading: false, HasError: false };
    case ExercisesTypes.SET_EXERCISES_FAILURE:
      return { ...state, IsLoading: false, HasError: true };
    default: return state;
  }
}