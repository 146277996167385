import React from "react";
import AccountMenu from "../AccountMenu";
import { Box, Typography } from "@mui/material";
import { SearchField } from "../../Components/Search";
import CrudLivros from "./components/EditableBooks";
import { SkeletonGrid } from "../../Components/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import BooksOperations from "../../InfraStructure/Books/BooksOperations";
import Toast from "../../Common/Helpers/Toast";
import Failure from "../../Components/Failure";
import { ResponseProps } from "../../@types/types";

export default function Books() {
  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [filteredData, setFilteredData] = React.useState<any[]>();

  const dispatch = useDispatch();
  const { IsLoading, HasError, Data } = useSelector(
    (state: any) => state.books
  );

  // const pdf1 = 'https://download.inep.gov.br/enem/provas_e_gabaritos/2022_PV_impresso_D1_CD1.pdf';

  const openToast = (message: string) => {
    setMessage(message);
    setOpen(true);
  }

  const getBooks = async () => {
    try {
      await dispatch<any>(BooksOperations.getBooks());
    } catch (error: string | unknown) {
      console.log('Erro buscando livros: ', error);
      openToast('Erro na rede. Tente novamente...');
    }
  };

  React.useEffect(() => {
    getBooks();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (Data) {
      setFilteredData(Data);
    }
  }, [Data]);

  const handleSave = async (data: any): Promise<ResponseProps> => {
    const formData = new FormData();
    formData.append('titulo', data.titulo);
    formData.append('nivel_id', data.nivel_id);
    formData.append('livro_estudante', data.livro_estudante);
    formData.append('livro_professor', data.livro_professor);
    formData.append('ativo', data.ativo ? '1' : '0');

    try {
      const response = await dispatch<any>(BooksOperations.createBook(formData));
      return response;
    } catch (error: string | unknown) {
      return { status: 'error', message: 'Erro ao salvar o livro' };
    }
  }

  const handleUpdate = async (data: any): Promise<ResponseProps> => {
    const formData = new FormData();
    formData.append('id', data.id);
    if (data.titulo) formData.append('titulo', data.titulo);
    if (data.nivel_id) formData.append('nivel_id', data.nivel_id);
    if (data.livro_estudante) formData.append('livro_estudante', data.livro_estudante);
    if (data.livro_professor) formData.append('livro_professor', data.livro_professor);
    if (data.ativo !== undefined) formData.append('ativo', data.ativo);

    try {
      const response = await dispatch<any>(BooksOperations.updateBook(formData));
      return response;
    } catch (error: string | unknown) {
      return { status: 'error', message: 'Erro ao atualizar o livro' };
    }
  }

  const handleDelete = async (id: number): Promise<boolean> => {
    try {
      await dispatch<any>(BooksOperations.deleteBook(id));
      return true;
    } catch (error: string | unknown) {
      console.log('Erro apagando livro: ', error);
      return false;
    }
  }

  if (HasError) {
    return (
      <React.Fragment>
        <Toast message={message} open={open} setclose={setOpen} severity="error" />
        <Failure message='Erro ao buscar livros' onClick={getBooks} />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <AccountMenu />

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'flex-start' }}>
        <Box sx={{ width: "95%", margin: 'auto', padding: 2 }}>
          <Typography
            variant="h5"
            sx={{ color: "#000", fontWeight: "bold", marginTop: 2, marginBottom: 2 }}>
            Books
          </Typography>
          
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              marginBottom: 3,
            }}>
            <SearchField
              width={270}
              data={Data}
              setSearch={setFilteredData}
              columnsToSearch={["titulo", "level"]}
              placeHolder="Buscar por título ou level"
            />
          </Box>
          
          {IsLoading
            ? <SkeletonGrid />
            : <CrudLivros
                data={filteredData}
                onSave={handleSave}
                onUpdate={handleUpdate}
                onDelete={handleDelete}
              />
          }
        </Box>
      </Box>
    </React.Fragment>
  );
}