import VideosTypes from "./Types";

const VideosActions = {
  SetLoading: () => ({
    type: VideosTypes.SET_VIDEOS_LOADING
  }),
  SetFailure: () => ({
    type: VideosTypes.SET_VIDEOS_FAILURE
  }),
  SetSuccess: (payload: any) => ({
    type: VideosTypes.SET_VIDEOS_SUCCESS,
    payload
  }),
}

export default VideosActions;