import FeedBackService from "../../Common/Services/FeedBackService";

interface FeedBackTypes {
  getFeedBack: () => Promise<any>;
}

const FeedBackOperations: FeedBackTypes = {
  getFeedBack: async () => {
    try {
      const response = await FeedBackService.getFeedBack();
      return response.data;
    } catch (error: string | unknown) {
      console.log('Erro buscando avaliações: ', error);
    }
  }
}

export default FeedBackOperations;