import { registerApplication, start } from "single-spa";
import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import App from "./App";

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary(err, info, props) {
    return <div>Ocorreu um erro na aplicação.</div>;
  }
});

registerApplication({
  name: 'etapa-idiomas',
  app: () => Promise.resolve(reactLifecycles),
  activeWhen: ['/']
});

start();

// ReactDOM.render(<App />, document.getElementById('root'));