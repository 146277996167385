import React, { useEffect } from "react";
import AccountMenu from "../AccountMenu";
import { Box, Typography } from "@mui/material";
import { SearchField } from "../../Components/Search";
import CrudChapters from "./components/EditableChapters";
import { SkeletonGrid } from "../../Components/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import ChaptersOperations from "../../InfraStructure/Chapters/Operations";
import Toast from "../../Common/Helpers/Toast";
import Failure from "../../Components/Failure";
import { ChapterProps } from "./components/types";
import { AppDispatch } from "../../InfraStructure/store";

export default function Chapters() {
  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [filteredData, setFilteredData] = React.useState<ChapterProps[]>();

  const dispatch = useDispatch<AppDispatch>();
  const { IsLoading, HasError, Data } = useSelector(
    (state: any) => state.chapters
  );

  const openToast = (message: string) => {
    setMessage(message);
    setOpen(true);
  }

  const getChapters = async () => {
    try {
      await dispatch(ChaptersOperations.getChapters());
    } catch (error: string | unknown) {
      console.log('Erro buscando capítulos: ', error);
      openToast('Erro na rede. Tente novamente...');
    }
  };

  useEffect(() => {
    getChapters();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Data) {
      setFilteredData(Data);
    }
  }, [Data]);

  const handleSave = async (data: any) => {

    const formData = new FormData();
    formData.append('nome', data.nome);
    formData.append('descricao', data.descricao);
    formData.append('nivel_id', data.nivel_id);
    formData.append('imagem', data.imagem);

    try {
      const response = await dispatch<any>(ChaptersOperations.createChapters(formData));
      return response;
    } catch (error: string | unknown) {
      console.log('Erro salvando capítulo: ', error);
      return { message: 'Erro na rede. Tente novamente...', status: 'error' };
    }
  }

  const handleDelete = async (id: number): Promise<boolean> => {
    try {
      await dispatch<any>(ChaptersOperations.deleteChapters(id));
      return true;
    } catch (error: string | unknown) {
      console.log('Erro deletando capítulo: ', error);
      return false;
    }
  }

  const handleUpdate = async (data: any) => {
    const formData = new FormData();
    formData.append('id', data.id);
    if (data?.nome) formData.append('nome', data.nome);
    if (data?.descricao) formData.append('descricao', data.descricao);
    if (data?.nivel_id) formData.append('nivel_id', data.nivel_id);
    if (data?.imagem) formData.append('imagem', data.imagem);

    try {
      const response = await dispatch<any>(ChaptersOperations.updateChapters(formData));
      return response;
    } catch (error: string | unknown) {
      console.log('Erro atualizando capítulo: ', error);
      return { message: 'Erro na rede. Tente novamente...', status: 'error' };
    }
  }

  if (HasError) {
    return (
      <React.Fragment>
        <Toast message={message} open={open} setclose={setOpen} severity="error" />
        <Failure message='Erro ao buscar capítulos' onClick={getChapters} />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <AccountMenu />

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'flex-start' }}>
        <Box sx={{ width: "95%", margin: 'auto', padding: 2 }}>
          <Typography
            variant="h5"
            sx={{ color: "#000", fontWeight: "bold", marginTop: 2, marginBottom: 2 }}>
            Capítulos
          </Typography>
          
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              marginBottom: 3,
            }}>
            <SearchField
              width={270}
              placeHolder="Buscar por nome, descrição..."
              data={Data}
              setSearch={setFilteredData}
              columnsToSearch={['nome', 'descricao']}
            />
          </Box>
          
          {IsLoading
            ? <SkeletonGrid />
            : <CrudChapters
                data={filteredData}
                onSave={handleSave}
                onDelete={handleDelete}
                onUpdate={handleUpdate}
              />
          }
        </Box>
      </Box>
    </React.Fragment>
  );
}