import VideosActions from "./Actions";
import VideosService from "../../Common/Services/VideosService";
import { ResponseProps } from "../../Page/Chapters/components/types";
import { AppDispatch } from "../store";

interface VideosOperationsType {
  getVideos: () => (dispatch: AppDispatch) => Promise<void>;
  createVideo: (formData: FormData) => (dispatch: AppDispatch) => Promise<ResponseProps>;
  updateVideo: (formData: FormData) => (dispatch: AppDispatch) => Promise<ResponseProps>;
  deleteVideos: (id: number) => (dispatch: AppDispatch) => Promise<ResponseProps>;
}

const VideosOperations: VideosOperationsType = {
  getVideos: () => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(VideosActions.SetLoading());
    try {
      const response = await VideosService.getVideos();
      const data = (response as { data: { data: any } }).data;
      dispatch(VideosActions.SetSuccess(data.data));
    } catch (error: string | unknown) {
      console.log('Erro buscando vídeos: ', error);
      dispatch(VideosActions.SetFailure());
    }
  },

  createVideo: (formData: FormData) => async (dispatch: AppDispatch): Promise<ResponseProps> => {
    try {
      const response = await VideosService.createVideo(formData);
      return response.data as unknown as ResponseProps;
    } catch (error: string | unknown) {
      console.log('Erro salvando vídeo: ', error);
      throw new Error(typeof error === 'string' ? error : 'Unknown error');
    }
  },

  updateVideo: (formData: FormData) => async (dispatch: AppDispatch): Promise<ResponseProps> => {
    try {
      const response = await VideosService.updateVideo(formData);
      return response.data as unknown as ResponseProps;
    } catch (error: string | unknown) {
      console.log('Erro atualizando vídeo: ', error);
      throw new Error(typeof error === 'string' ? error : 'Unknown error');
    }
  },

  deleteVideos: (id: number) => async (dispatch: AppDispatch): Promise<ResponseProps> => {
    try {
      await VideosService.deleteVideo(id);
      return { message: 'Vídeo apagado com sucesso.', status: 'success' };
    } catch (error: string | unknown) {
      console.log('Erro deletando vídeo: ', error);
      throw new Error(typeof error === 'string' ? error : 'Unknown error');
    }
  }
}

export default VideosOperations;