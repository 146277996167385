import * as React from 'react';
import Box from '@mui/material/Box';
import { FormControlLabel, Switch, TextField } from '@mui/material';

const handleAnswerChange = (event, resposta, setResposta, index) => {
  if(resposta?.alternativas){
    let tempAlternativas = resposta?.alternativas;
    tempAlternativas[index].resposta = event.target.value as string;
    setResposta({...resposta, alternativas: tempAlternativas});
  }
}

const handleQuestionChange = (event, questao, setQuestao, index) => {
  if(questao?.alternativas){
    let tempAlternativas = questao?.alternativas;
    tempAlternativas[index].questao = event.target.value as string;
    setQuestao({...questao, alternativas: tempAlternativas});
  }
}

const FillInTheGaps = ({questao, setQuestao, resposta, setResposta, index}: any) => {

  const [qAlternativa, setQAlternativa] = React.useState(questao?.alternativas[index] ?? []);  
  const [rAlternativa, setRAlternativa] = React.useState(resposta?.alternativas[index] ?? []);

  React.useEffect(()=>{
    setQAlternativa(questao?.alternativas[index] ?? []);
    // eslint-disable-next-line
  },[questao]);

  React.useEffect(()=>{
    setRAlternativa(resposta?.alternativas[index] ?? []);
    // eslint-disable-next-line
  },[resposta]);

  const handleStartChange = (event) => {
    if(questao?.alternativas){
      let tempAlternativas = questao?.alternativas;
      let tempQuestao = questao;
      setQuestao(null)
      tempAlternativas[index].inicio = event.target.value as string;
      setQuestao({...tempQuestao, alternativas: tempAlternativas});
    }
  };

  const handleEndChange = (event) => {
    if(questao?.alternativas){
      let tempAlternativas = questao?.alternativas;
      tempAlternativas[index].final = event.target.value as string;
      setQuestao({...questao, alternativas: tempAlternativas});
    }
  }
  
  return (
    <Box className='row' style={{alignItems: 'center'}}>
      <strong>
        {qAlternativa.alternativa})
      </strong>
      <TextField value={qAlternativa.inicio} onChange={handleStartChange} label="Frase de abertura" variant="outlined" className='flex2 txtQuestion' size='small' />
      <TextField label="Resposta" value={rAlternativa.resposta} onChange={(e)=>handleAnswerChange(e, resposta, setResposta, index)} variant="outlined" className='flex1 txtQuestion' size='small' />
      <TextField value={qAlternativa.final} onChange={handleEndChange} label="Final da frase" variant="outlined" className='flex2 txtQuestion' size='small' />
    </Box>
  );
}

const UnscrambleTheSentences = ({questao, setQuestao, resposta, setResposta, index}: any) => {

  const [qAlternativa, setQAlternativa] = React.useState(questao?.alternativas[index] ?? []);  

  React.useEffect(()=>{
    setQAlternativa(questao?.alternativas[index] ?? []);
    // eslint-disable-next-line
  },[questao]);

  const handleOrderChange = (event) => {
    handleQuestionChange(event, questao, setQuestao, index);
    handleAnswerChange(event, resposta, setResposta, index);
  };

  return (
    <Box className='row' style={{alignItems: 'center'}}>
      <strong>
        {qAlternativa.alternativa})
      </strong>
      <TextField value={qAlternativa.questao} onChange={handleOrderChange}  variant="outlined" className='flex1 txtQuestion' multiline rows={4} size='small' />
    </Box>
  );
}

const NumberTheSentences = ({questao, setQuestao, resposta, setResposta, index}: any) => {

  const [qAlternativa, setQAlternativa] = React.useState(questao?.alternativas[index] ?? []); 

  React.useEffect(()=>{
    setQAlternativa(questao?.alternativas[index] ?? []);
    // eslint-disable-next-line
  },[questao]);

  const handleOrderChange = (event) => {
    handleQuestionChange(event, questao, setQuestao, index);
    handleAnswerChange(event, resposta, setResposta, index);
  };

  return (
    <Box className='row' style={{alignItems: 'center'}}>
      <strong>
        Ordem: #0{index+1}
      </strong>
      <div style={{width: 10}}/>
      <TextField value={qAlternativa.questao} onChange={handleOrderChange} variant="outlined" className='flex1 txtQuestion' multiline size='small' label='Sentença'  />
    </Box>
  );
}

const ChooseTheCorrectAnswer = ({questao, setQuestao, resposta, setResposta, index}: any) => {

  const [qAlternativa, setQAlternativa] = React.useState(questao?.alternativas[index] ?? []);  
  const [rAlternativa, setRAlternativa] = React.useState(resposta?.alternativas[index] ?? []);

  React.useEffect(()=>{
    setQAlternativa(questao?.alternativas[index] ?? []);
    // eslint-disable-next-line
  },[questao]);

  React.useEffect(()=>{
    setRAlternativa(resposta?.alternativas[index] ?? []);
    // eslint-disable-next-line
  },[resposta]);

  const handleCheck = (event) => {
    if(resposta?.alternativas){
      let tempAlternativas = resposta?.alternativas;
      if(resposta.type == '5'){
        tempAlternativas[index].resposta = event.target.checked;
        setResposta({...resposta, alternativas: tempAlternativas});
      }else{
        tempAlternativas = resposta?.alternativas?.map((alternativa, i)=>({...alternativa, resposta: index === i? event.target.checked : false,}));
        setResposta({...resposta, alternativas: tempAlternativas, resposta: rAlternativa.alternativa});        
      }
    }
  }

  return (
    <Box className='row' style={{alignItems: 'center'}}>
      <strong>
        {qAlternativa.alternativa})
      </strong>
      <TextField value={qAlternativa.questao} onChange={(e) => handleQuestionChange(e, questao, setQuestao, index)} variant="outlined" className='flex1 txtQuestion' size='small' />
      <div style={{width: 15}}/>
      <FormControlLabel control={<Switch checked={rAlternativa.resposta} onChange={handleCheck} />} label={questao.type == 5 ?"Verdadeiro?":"Correta?"} />
    </Box>
  );
}

const WriteTheSentenses = ({questao, setQuestao, resposta, setResposta, index}: any) => {
  const [qAlternativa, setQAlternativa] = React.useState(questao?.alternativas[index] ?? []);  
  const [rAlternativa, setRAlternativa] = React.useState(resposta?.alternativas[index] ?? []);

  React.useEffect(()=>{
    setQAlternativa(questao?.alternativas[index] ?? []);
    // eslint-disable-next-line
  },[questao]);

  React.useEffect(()=>{
    setRAlternativa(resposta?.alternativas[index] ?? []);
    // eslint-disable-next-line
  },[resposta]);

  return (
    <Box className='col'>
      <Box className='row' style={{alignItems: 'center'}}>
        <strong>
          {qAlternativa.alternativa})<div style={{width: 15}}/>
        </strong>
        <TextField value={qAlternativa.questao} onChange={(e) => handleQuestionChange(e, questao, setQuestao, index)}  variant="outlined" className='flex1 txtQuestion' size='small' />
      </Box>
      <TextField value={rAlternativa.resposta} onChange={(e) => handleAnswerChange(e, resposta, setResposta, index)}  variant="outlined" className='flex1 txtQuestion' multiline rows={3} size='small' />
    </Box>
  );
}

export default (exerciseType, questao, setQuestao, resposta, setResposta, index) => {
  switch(exerciseType+""){
    case '1':
    case '9': 
      return <FillInTheGaps questao={questao} setQuestao={setQuestao} resposta={resposta} setResposta={setResposta} index={index} />
    case '2':
      return <UnscrambleTheSentences questao={questao} setQuestao={setQuestao} resposta={resposta} setResposta={setResposta} index={index} />
    case '3':
    case '6':
      return <WriteTheSentenses questao={questao} setQuestao={setQuestao} resposta={resposta} setResposta={setResposta} index={index} />
    case '4':
    case '5':
    case '7':
      return <ChooseTheCorrectAnswer questao={questao} setQuestao={setQuestao} resposta={resposta} setResposta={setResposta} index={index} />
    case '8':
      return <NumberTheSentences questao={questao} setQuestao={setQuestao} resposta={resposta} setResposta={setResposta} index={index} />
  }
}
