import ChaptersTypes from "./Types";

const ChaptersActions = {
  SetLoading: () => ({
    type: ChaptersTypes.SET_CHAPTERS_LOADING
  }),
  SetFailure: () => ({
    type: ChaptersTypes.SET_CHAPTERS_FAILURE
  }),
  SetSuccess: (payload: any) => ({
    type: ChaptersTypes.SET_CHAPTERS_SUCCESS,
    payload
  }),
}

export default ChaptersActions;