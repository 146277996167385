import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Refresh } from "@mui/icons-material";

const Failure = ({
  message,
  onClick,
}: {
  message: string;
  onClick: () => void;
}) => {
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: window.innerHeight - 75 }}>
        <Typography variant='h5' sx={{ color: '#000', fontWeight: 'bold', marginTop: 2, marginBottom: 2 }}>{message}</Typography>
        <Button variant='contained' onClick={onClick}>
          <Refresh sx={{ color: '#fff' }} />
          &nbsp;
          <Typography variant='button' sx={{ color: '#fff', fontSize: 16, fontWeight: 'bold' }}>Tentar novamente</Typography>
        </Button>
      </Box>
    </React.Fragment>
  )
}

export default Failure;