import * as React from 'react';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import { CustomGridToolbarProps, ResponseProps } from '../../../@types/types';
import { CheckBox, Close } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { CircularProgress, Tooltip } from '@mui/material';
import { successToast } from '../../../Common/Helpers/Utils';
import { StudentProps } from './types';
import Toast from '../../../Common/Helpers/Toast';

type DataProps = {
  data: GridRowsProp;
  onSave?: (data: StudentProps) => Promise<ResponseProps>;
};

export default function CrudStudents({ data, onSave }: DataProps) {
  const [rows, setRows] = React.useState(data);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  // eslint-disable-next-line
  const [mode, setMode] = React.useState<'view' | 'edit'>('edit');
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState({ id: undefined, loading: false });
  
  const { Data, IsLoading } = useSelector(
    (state: any) => state.rooms
  );

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!?.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    console.log('Dados a gravar', newRow);

    if (newRow?.idiomas_sala == null) {
      setMessage('Selecione uma sala para o aluno');
      setOpen(true);
      return;
    }

    setLoading({ id: newRow.num_aluno, loading: true });
    try {
      const response = await onSave(newRow as StudentProps);

      if (response.status === 'success') {
        setLoading({ id: undefined, loading: false });
        successToast('Sala atualizada!');
        setRows(rows.map((row) => (row.num_aluno === newRow.num_aluno ? newRow : row)));
        return newRow;
      }
    } catch (error) {
      console.log('Erro ao atualizar sala', error);
      setMessage('Erro ao atualizar sala');
      setOpen(true);
    }

  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleVerifyEditMode = (params: any) => {
    return true;
  }

  React.useEffect(() => {
    setRows(data);
  }, [data]);

  const columns: GridColDef[] = [
    {
      field: 'num_aluno',
      minWidth: 100,
      flex: 1,
      editable: false,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>Nº Aluno</strong>,
    },
    {
      field: 'nome',
      minWidth: 180,
      flex: 1,
      editable: false,
      type: 'string',
      headerAlign: 'left',
      renderHeader: () => <strong>Nome</strong>,
    },
    {
      field: 'email',
      minWidth: 180,
      flex: 1,
      editable: false,
      type: 'string',
      headerAlign: 'left',
      renderHeader: () => <strong>E-mail</strong>,
    },
    {
      field: 'curso',
      minWidth: 200,
      flex: 1,
      editable: false,
      type: 'string',
      headerAlign: 'left',
      renderHeader: () => <strong>Curso</strong>,
    },
    {
      field: 'idiomas_sala',
      minWidth: 100,
      flex: 1,
      editable: true,
      type: 'singleSelect',
      headerAlign: 'center',
      align: 'center',
      valueOptions: Data?.map((room: any) => {
        return { value: room.id, label: room.sala }
      }),
      renderHeader: () => <strong>Sala</strong>,
      renderCell(params) {
        if (IsLoading) {
          return <CircularProgress size={20} />
        }
        if (params.value) {
          // eslint-disable-next-line
          const room = Data.find((room: any) => room.id == params.value);
          return room
            ? room?.sala
            : <Close color="disabled" />;
        }

        return <Close color="disabled" />;
      },
    },
    {
      field: 'idiomas',
      type: 'string',
      minWidth: 80,
      flex: 1,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>Ativo</strong>,
      renderCell: (params) => (
        params.value
          ? <CheckBox color="primary" />
          : <Tooltip title="Ainda não cadastrado"><Close color="disabled" /></Tooltip>
      ),
    },
    {
      field: 'created_at',
      headerAlign: 'center',
      type: 'string',
      minWidth: 100,
      flex: 1,
      editable: false,
      align: 'center',
      renderHeader: () => <strong>Criado em</strong>,
    },
    {
      field: 'actions',
      type: 'actions',
      renderHeader: () => <strong>Ações</strong>,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            disabled
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <Toast
        message={message}
        open={open}
        setclose={setOpen}
        severity="error"
      />
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        disableColumnMenu
        onRowDoubleClick={handleVerifyEditMode}
        getRowId={(row) => row.num_aluno}
        autosizeOptions={{
          columns: ['num_aluno', 'nome', 'email', 'sala', 'idiomas', 'created_at'],
          includeOutliers: true,
          includeHeaders: true,
        }}
        localeText={{
          noRowsLabel: 'Nenhum(a) professor(a) encontrado(a).',
          footerRowSelected: (count) => `${count} aluno(s) selecionado(s)`,
        }}
        columnVisibilityModel={{
          id: false,
          created_at: mode !== 'view',
        }}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slotProps={{
          toolbar: { setRows, setRowModesModel } as CustomGridToolbarProps,
          pagination: {
            labelRowsPerPage: "Usuários por página",
            labelDisplayedRows: ({ from, to, count }) => `${from}-${to} de ${count}`,
          }
        }}
      />
    </Box>
  );
}