import * as React from 'react';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

interface ToastProps {
  message: string;
  duration?: number;
  open: boolean;
  setclose: (close: boolean) => void;
  severity?: 'success' | 'error' | 'warning' | 'info';
};

export default function Toast({
  message,
  duration = 5000,
  open,
  setclose,
  severity = 'success',
}: ToastProps) {

const handleClose = (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
  if (reason === 'clickaway') {
    return;
  }
  setclose(false);
};

return (
  <Snackbar
    open={open}
    autoHideDuration={duration}
    onClose={handleClose}anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
      <Alert
        onClose={handleClose}
        severity={severity}
        variant='filled'
        sx={{ w: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}