import Api from "../Helpers/Api";

class TeachersService {
  getTeachers() {
    return Api.get(`/professores`);
  }

  checkUser(email: string) {
    return Api.get(`/professores/checkUser/${email}`);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new TeachersService();