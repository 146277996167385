import { create } from 'apisauce';
import { userData } from '../../Components/User';

const Api = create({
  baseURL: 'https://apietapaidiomas.grupoetapa.com.br/api', 
  // baseURL: 'http://127.0.0.1:8000/api',
  timeout: 30000,
  withCredentials: true,
});

const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
const userInfo = userData();
const bearerToken = userInfo ? userInfo.token : '';

Api.addResponseTransform((response) => {
  if (response.status === 401 && response.data.message === 'Token expirado!') {
    localStorage.clear();
    window.location.reload();
  }

  if (response.status >= 400 || !response.ok) {
    throw response;
  }

  return response;
});

Api.addAsyncRequestTransform((request: any) => async () => {
  try {
    const token = bearerToken;

    request.headers = {
      Authorization: `Bearer ${token}`,
      'X-CSRF-TOKEN': csrfToken,
    };
  } catch (error: any) {
    console.log('Erro adicionando token no Header', error);
  }

  return request;
});

export default Api;
