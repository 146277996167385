import { UnknownAction } from "redux";
import HomeworksActions from "./Actions";
import HomeworksService from "../../Common/Services/HomeworksService";
import { ResponseProps } from "../../Page/Homeworks/components/types";

interface Dispatch {
  (action: UnknownAction): void;
}

interface HomeworksOperationsType {
  getHomeworks: () => (dispatch: Dispatch) => Promise<void>;
  getHomework: (id:number) => (dispatch: Dispatch) => Promise<void>;
  getTypes: () => (dispatch: Dispatch) => Promise<void>;
  createHomework: (formData: FormData) => (dispatch: Dispatch) => Promise<ResponseProps>;
  updateHomework: (formData: FormData) => (dispatch: Dispatch) => Promise<ResponseProps>;
  deleteHomework: (id: number) => (dispatch: Dispatch) => Promise<ResponseProps>;
}

const HomeworksOperations: HomeworksOperationsType = {
  getHomeworks: () => async (dispatch: Dispatch): Promise<void> => {
    dispatch(HomeworksActions.SetLoading());
    try {
      const response = await HomeworksService.getHomeworks();
      dispatch(HomeworksActions.SetSuccess(response.data, 'homeworks'));
    } catch (error: string | unknown) {
      console.log('Erro buscando tarefas: ', error);
      dispatch(HomeworksActions.SetFailure());
    }
  },
  getHomework: (id:number) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(HomeworksActions.SetLoading());
    try {
      const response = await HomeworksService.getHomework(id);
      dispatch(HomeworksActions.SetSuccess(response.data, 'homeworks'));
    } catch (error: string | unknown) {
      console.log('Erro buscando tarefas: ', error);
      dispatch(HomeworksActions.SetFailure());
    }
  },
  getTypes: () => async (dispatch: Dispatch): Promise<void> => {
    dispatch(HomeworksActions.SetLoading());
    try {
      const response = await HomeworksService.getTypes();
      dispatch(HomeworksActions.SetSuccess(response.data, 'types'));
    } catch (error: string | unknown) {
      console.log('Erro buscando os tipos de tarefa: ', error);
      dispatch(HomeworksActions.SetFailure());
    }
  },
  createHomework: (formData: FormData) => async (dispatch: Dispatch): Promise<ResponseProps> => {
    try {
      const response = await HomeworksService.createHomework(formData);
      return response.data as unknown as ResponseProps;
    } catch (error: string | unknown) {
      console.log('Erro salvando tarefa: ', error);
      throw new Error(typeof error === 'string' ? error : 'Unknown error');
    }
  },
  updateHomework: (formData: FormData) => async (dispatch: Dispatch): Promise<ResponseProps> => {
    try {
      const response = await HomeworksService.updateHomework(formData);
      return response.data as unknown as ResponseProps;
    } catch (error: string | unknown) {
      console.log('Erro atualizando tarefa: ', error);
      throw new Error(typeof error === 'string' ? error : 'Unknown error');
    }
  },
  deleteHomework: (id: number) => async (dispatch: Dispatch): Promise<ResponseProps> => {
    try {
      await HomeworksService.deleteHomework(id);
      return { message: 'Tarefa deletada com sucesso.', status: 'success' };
    } catch (error: string | unknown) {
      console.log('Erro deletando tarefa: ', error);
      throw new Error(typeof error === 'string' ? error : 'Unknown error');
    }
  }
}

export default HomeworksOperations;