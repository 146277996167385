import RoomsTypes from "./Types";

const RoomsActions = {
  SetLoading: () => ({
    type: RoomsTypes.SET_ROOMS_LOADING
  }),
  SetFailure: () => ({
    type: RoomsTypes.SET_ROOMS_FAILURE
  }),
  SetSuccess: (payload: any) => ({
    type: RoomsTypes.SET_ROOMS_SUCCESS,
    payload
  }),
};

export default RoomsActions;