import { Close, Search } from "@mui/icons-material";
import { FilledInput, FormControl, IconButton, InputAdornment, InputLabel, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { SearchFieldProps } from "../../@types/search";

export const SearchField = ({
  placeHolder,
  width,
  setSearch,
  data,
  columnsToSearch,
}: SearchFieldProps) => {
  const [dataSearch, setDataSearch] = React.useState<string>('');

  const handleSearch = useCallback((value: any) => {
    setDataSearch(value);
    if (!columnsToSearch) {
      const chave = Object.entries(data[0]).map((key) => key[0]);
      columnsToSearch = [chave[1]];
    }

    if (data && columnsToSearch) {
      const filteredData = data.filter((item) => {
        return columnsToSearch?.some((column) => {
          if (typeof item[column] === "number") {
            return item[column].toString().includes(value);
          }

          return item[column]?.toLowerCase().includes(value?.toLowerCase());
        });
      });

      setSearch!(filteredData);
    }
  }, [data, setSearch]);

  const handleClose = useCallback(() => {
    setDataSearch('');
    setSearch!(data);
  }, [data, setSearch]);

  return (
    <React.Fragment>
      <Typography variant="body1" sx={{ color: "#A7A7A7", fontWeight: "normal", marginTop: 2 }}>Filtros</Typography>

      <FormControl sx={{ width: width ?? 300 }} variant="filled">
        <InputLabel
          sx={{ color: "#A7A7A7", fontSize: 14 }}
          htmlFor="filled-adornment-password">
          {placeHolder}
        </InputLabel>
        <FilledInput
          id="filled-adornment-password"
          sx={{ backgroundColor: "transparent", fontSize: 12 }}
          // type={showPassword ? 'text' : 'password'}
          type="text"
          value={dataSearch}
          onChange={(e) => handleSearch(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                edge="end"
              >
                {dataSearch.length < 1 ? <Search /> : <Close onClick={handleClose} />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </React.Fragment>
  );
}