import Menu from "../Page/Menu";
import Schools from "../Page/Schools";
import Rooms from "../Page/Rooms";
import Chapters from "../Page/Chapters";
import Homeworks from "../Page/Homeworks";
import Exercises from "../Page/Exercises";
import Speaking from "../Page/Speaking";
import FlashCards from "../Page/FlashCards";
import Videos from "../Page/Videos";
import Books from "../Page/Books";
import Jogos from "../Page/Jogos";
import Administrators from "../Page/Administrators";
import Coordinators from "../Page/Coordinators";
import Teachers from "../Page/Teachers";
import Students from "../Page/Students";
import Documents from "../Page/Documents";
import Feedback from "../Page/Feedback";
import Settings from "../Page/Settings";
import Administrator from "../Page/Administrator";
import ExercisesEdit from "../Page/ExercisesEdit";
import RoomHours from "../Page/Hours";
import Login from "../Page/Login";
import Logout from "../Page/Logout";
import EtapaDigital from "../Page/EtapaDigital";
import Questions from "../Page/Questions";

export {
  Menu,
  Schools,
  Rooms,
  Chapters,
  Homeworks,
  Exercises,
  ExercisesEdit,
  Speaking,
  FlashCards,
  Videos,
  Books,
  Jogos,
  Administrators,
  Coordinators,
  Teachers,
  Students,
  Documents,
  Feedback,
  Settings,
  Administrator,
  RoomHours,
  Login,
  Logout,
  EtapaDigital,
  Questions,
}