import SchoolTypes from "./Types";

const SchoolActions = {
  SetLoading: () => ({
    type: SchoolTypes.SET_SCHOOLS_LOADING
  }),
  SetFailure: () => ({
    type: SchoolTypes.SET_SCHOOLS_FAILURE
  }),
  SetSuccess: (payload: any) => ({
    type: SchoolTypes.SET_SCHOOLS_SUCCESS,
    payload
  }),
}

export default SchoolActions;