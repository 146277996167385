import { UnknownAction } from "redux";
import CoordinatorsActions from "./Actions";
import CoordinatorsService from "../../Common/Services/CoordinatorsService";

interface Dispatch {
  (action: UnknownAction): void;
}

interface CoordinatorsTypes {
  getCoordinators: () => (dispatch: Dispatch) => Promise<void>;
}

const CoordinatorsOperations: CoordinatorsTypes = {
  getCoordinators: () => async (dispatch: Dispatch): Promise<void> => {
    dispatch(CoordinatorsActions.SetLoading());
    try {
      const response = await CoordinatorsService.getCoordinators();
      dispatch(CoordinatorsActions.SetSuccess(response.data));
    } catch (error: string | unknown) {
      console.log('Erro buscando coordenadores: ', error);
      dispatch(CoordinatorsActions.SetFailure());
    }
  }
}

export default CoordinatorsOperations;