import { UnknownAction } from "redux";
import SchoolService from "../../Common/Services/SchoolService"
import SchoolActions from "./Actions";

interface Dispatch {
  (action: UnknownAction): void;
}

interface SaveSchoolProps {
  message: string;
  status: string;
}

interface SchoolOperationsType {
  getSchools: () => (dispatch: Dispatch) => Promise<void>;
  saveSchool: (data: any) => () => Promise<SaveSchoolProps>;
  editSchool: (data: any) => () => Promise<SaveSchoolProps>;
  deleteSchool: (id: number) => () => Promise<SaveSchoolProps>;
}

const SchoolOperations: SchoolOperationsType = {
  getSchools: () => async (dispatch: Dispatch): Promise<void> => {
    dispatch(SchoolActions.SetLoading());

    try {
      const response = await SchoolService.getSchools();
      dispatch(SchoolActions.SetSuccess(response.data));
    } catch (error) {
      console.log('Operations error', error);
      dispatch(SchoolActions.SetFailure());
      throw new Error(error);
    }
  },

  saveSchool: (data: any) => async (): Promise<SaveSchoolProps> => {
    try {
      await SchoolService.saveSchool(data);
      return { message: 'Escola salva com sucesso.', status: 'success' };
    } catch (error) {
      console.log('Operations error', error);
      throw new Error(error);
    }
  },

  editSchool: (data: any) => async (): Promise<SaveSchoolProps> => {
    try {
      await SchoolService.editSchool(data);
      return { message: 'Escola editada com sucesso.', status: 'success' };
    } catch (error) {
      console.log('Operations error', error);
      throw new Error(error);
    }
  },

  deleteSchool: (id: number) => async (): Promise<SaveSchoolProps> => {
    try {
      await SchoolService.deleteSchool(id);
      return { message: 'Escola deletada com sucesso.', status: 'success' };
    } catch (error) {
      console.log('Operations error', error);
      throw new Error(error);
    }
  }
}

export default SchoolOperations;