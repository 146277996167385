import RoomsTypes from "./Types";

const INITIAL_STATE = {
  Data: [],
  IsLoading: true,
  HasError: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case RoomsTypes.SET_ROOMS_LOADING:
      return { ...state, IsLoading: true, HasError: false };
    case RoomsTypes.SET_ROOMS_SUCCESS:
      return { Data: action.payload, IsLoading: false, HasError: false };
    case RoomsTypes.SET_ROOMS_FAILURE:
      return { ...state, IsLoading: false, HasError: true };
    default: return state;
  }
}