import {
  AddPhotoAlternate,
  Audiotrack,
  CheckBox,
  CheckBoxOutlineBlank,
  Delete,
  DeleteOutline,
  PictureAsPdf,
  PostAdd,
} from "@mui/icons-material";
import {
  Box,
  Button,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import theme from "../../Theme";
import Swal from 'sweetalert2';
import { LevelProps } from "../../@types/types";
import { ChapterProps } from "../../Page/Chapters/components/types";
import Iframe from "react-iframe";
// import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
// import MUIRichTextEditor from "mui-rte";
import { SchoolListProps } from "../../Page/Schools/components/types";


export const isUrl = (url: string) => {
  if (url.includes('http')) {
    return true;
  }
  return false;
}

export const showPdf = (pdf: string) => {
  const height = window.innerHeight * 0.8;
  return (
    Swal.fire({
      html: `<iframe src="${pdf}" width="100%" height=${height}></iframe>`,
      padding: 10,
      confirmButtonText: 'Fechar',
    })
  );
}

export const showImage = (image: any) => {
  // const imageWidth = image?.target?.width > window.innerWidth
  //   ? window.innerWidth * 0.8
  //   : image?.target?.width;
  // const imageHeight = image?.target?.height > window.innerHeight
  //   ? window.innerHeight * 0.8
  //   : image?.target?.height;

  return (
    Swal.fire({
      imageUrl: typeof image === 'string' ? image : image.target.src,
      padding: 10,
      // imageWidth: imageWidth,
      // imageHeight: imageHeight,
      confirmButtonText: 'Fechar',
    })
  );
}

export const ShowAudio = (audio: any) => {
  return (
    <audio controls style={{ width: "100%", marginTop: 10 }}>
      <source src={audio} type="audio/mpeg" />
    </audio>
  )
}

export const resizeImage = (file: File, x: number = 1920, y: number = 1080) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const img = new Image();
      img.src = event?.target?.result as string;
      img.onload = () => {
        const MAX_WIDTH = x;
        const MAX_HEIGHT = y;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
          if (blob) {
            resolve(new File([blob], file.name, { type: 'image/jpeg', lastModified: Date.now() }));
          } else {
            console.log('Erro redimensionando imagem...');
          }
        }, 'image/jpeg', 0.7);
      };
    };
  });
};

interface AudioUploaderProps {
  params: any;
  format?: 'default' | 'json'  
}

export const AudioUploader = ({ params, format = 'default' }: AudioUploaderProps) => {
  const json = format === 'json';
  const { value, api, id, mt } = params;
  const [audio, setAudio] = React.useState((json ? value?.audio : value) || '');
  const edit = api.getCellMode(id, json ? 'conteudo' : 'imagem') === 'edit';

  const handleChange = (event: any) => {
    const file = event.target.files[0];

    setAudio(URL.createObjectURL(file));
    api.setEditCellValue({ id, field: json ? 'conteudo' : 'audio', value: (json ? {...value, "audio": file} : file ) });
  };

  return (
    audio ? (
      <Box sx={{ height: 76, minWidth: "100%", textAlign: "center", padding: 0.5 }}>
        <audio
          controls
          style={{
            width: "90%",
          }}>
          <source
            src={typeof audio === 'string' ? audio : audio.target.src}
            type="audio/mpeg"
            style={{ backgroundColor: theme.palette.primary.main }}
          />
        </audio>
        {edit && (
          <DeleteOutline
            style={{
              marginLeft: 5,
              marginBottom: 20,
              cursor: 'pointer',
              color: theme.palette.primary.main,
            }}
            onClick={() => {
              setAudio('');
            }}
          />
        )}
      </Box>
    ) : (
      <Box style={{
        width: "100%",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
        <input
          id={`id-${id}`}
          type='file'
          accept="audio/*"
          onChange={handleChange}
          style={{ display: 'none' }}
        />
        <Button
          variant="outlined"
          component="label"
          color="primary"
          size="small"
          style={{ width: 80, height: 40, marginTop: mt }}
          onClick={() => document.getElementById(`id-${id}`)?.click()}
        >
          <Audiotrack style={{ fontSize: 26 }} />
        </Button>
      </Box>
    )
  );
};

interface ImageUploaderProps {
  params: any;
  format?: 'default' | 'json'
}

export const ImageUploader = ({ params, format='default' }: ImageUploaderProps) => {
  const json = format === 'json';
  const { value, api, id, height } = params;
  const [image, setImage] = React.useState((json ? value?.imagem : value ) || '');
  const edit = api.getCellMode(id, json ? 'conteudo' : 'imagem') === 'edit';

  const handleChange = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = async () => {
      const imageBlob = await resizeImage(file) as Blob;
      setImage(URL.createObjectURL(imageBlob));
      api.setEditCellValue({ id, field: json ? 'conteudo' : 'imagem', value: (json ? {...value, "imagem": imageBlob} : imageBlob )});
    };

    reader.readAsDataURL(file);
  }

  return (
    image ? (
      <Box style={{
        height: height,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
        <img
          src={image}
          onClick={showImage}
          alt="Capa do capítulo"
          style={{ maxWidth: edit ? 100 : "100%", height: height - 4, cursor: 'pointer' }}
        />
        {edit && (
          <Delete
            style={{
              marginLeft: 5,
              cursor: 'pointer',
              color: theme.palette.primary.main,
            }}
            onClick={() => {
              setImage('');
            }}
          />
        )}
      </Box>
    ) : (
      <Box style={{
        width: "100%",
        height: height,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
        <input
          id={id}
          type='file'
          accept="image/*"
          onChange={handleChange}
          style={{ display: 'none' }}
        />
        <Button
          variant="outlined"
          component="label"
          color="primary"
          size="small"
          style={{ width: 80, height: 40 }}
          onClick={() => document.getElementById(id)?.click()}
        >
          <AddPhotoAlternate style={{ fontSize: 26 }} />
        </Button>
      </Box>
    )
  );
};

interface PDFUploaderProps {
  fileUrl: string;
  width?: string | number;
  height?: string | number;
};

export const PDFViewer = ({ fileUrl, width = '100%', height = '100%' }: PDFUploaderProps) => {
  // pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  //   'pdfjs-dist/build/pdf.worker.min.mjs',
  //   import.meta.url,
  // ).toString();

  return (
    <Box style={{ width, height }}>
      <Iframe
        url={fileUrl}
        width="100%"
        height="100%"
      />
    </Box>
    // <Document
    //   file={pdfBook}
      
    //   onLoadError={(error) => console.log('Erro:', error)}
    //   onLoadSuccess={(pdf) => console.log('PDF:', pdf)}>
    //   <Page />
    // </Document>
  );
};

interface PdfUploaderProps {
  params: any;
  field: string;
}

export const PdfUploader = ({ params, field }: PdfUploaderProps) => {
  const { value, api, id, height } = params;
  const [pdf, setPdf] = React.useState(value || '');
  const edit = api.getCellMode(id, field) === 'edit';
  // const status = api.getCellMode(id, 'status');

  const handleChange = (event: any) => {
    const file = event.target.files[0];
    setPdf(URL.createObjectURL(file));
    api.setEditCellValue({ id, field: field, value: file });
  }

  return (
    pdf ? (
      <Box style={{
        height: height,
        width: '100%',
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{ width: 80, height: 40 }}
          onClick={() => showPdf(pdf)}>
          <PictureAsPdf
            height={height}
            style={{ cursor: 'pointer' }}
          />&nbsp;Ver
        </Button>
        {edit && (
          <Delete
            style={{
              marginLeft: 5,
              cursor: 'pointer',
              color: theme.palette.primary.main,
            }}
            onClick={() => {
              setPdf('');
            }}
          />
        )}
      </Box>
    ) : (
      <Box style={{
        width: "100%",
        height: height,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
        <input
          id={id}
          type='file'
          accept="application/pdf"
          onChange={handleChange}
          style={{ display: 'none' }}
        />
        <Button
          variant="outlined"
          component="label"
          color="primary"
          size="small"
          style={{ width: 80, height: 40 }}
          onClick={() => document.getElementById(id)?.click()}
        >
          <PostAdd style={{ fontSize: 26 }} />
        </Button>
      </Box>
    )
  );
};

export const Choice = ({ params, setActive }: any) => {
  const { value, api, id, height } = params;
  const edit = api.getCellMode(id, 'ativo') === 'edit';

  const handleChange = (event: any) => {
    api.setEditCellValue({ id, field: 'ativo', value: event.target.value });
    setActive(event.target.value);
  }

  React.useEffect(() => {
    setActive(value);
    api.setEditCellValue({ id, field: 'ativo', value });
    // eslint-disable-next-line
  }, [value]);

  return (
    edit ? (
      <Box style={{
        width: 100,
        height: height,
        display: "flex",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
      }}>
        <Select
          defaultValue={value}
          style={{
            width: 90,
            height: height > 40 ? 40 : height,
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 14,
          }}
          onChange={handleChange}
        >
          <MenuItem sx={{ textAlign: "center", padding: 1 }} value={1}>Sim</MenuItem>
          <MenuItem sx={{ textAlign: "center", padding: 1 }} value={0}>Não</MenuItem>
        </Select>
      </Box>
    ) : (
      { true: 'Sim', false: 'Não' }[value]
    )
  );
}

export const Level = ({ params, setLevel }: any) => {
  const { value, api, id, levels } = params;
  const col = params?.column ?? 'nivel';
  const edit = api.getCellMode(id, col) === 'edit';

  // eslint-disable-next-line
  const level = levels.find((level: LevelProps) => level.id == value)?.nivel;

  const handleChangeLevel = (event: any) => {
    const nivel = levels.find((n: any) => n.id === event.target.value)?.nivel;
    api.setEditCellValue({ id, field: col, value: event.target.value });
    setLevel({ id: event.target.value, nivel });
  }

  React.useEffect(() => {
    setLevel({ id: value, nivel: level });
    // eslint-disable-next-line
  }, []);

  return (
    edit ? (
      <Box style={{
        width: "100%",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
        <Select
          defaultValue={value}
          style={{
            width: "90%",
            height: 40,
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 14,
          }}
          onChange={handleChangeLevel}
        >
          {levels.map((level: LevelProps) => (
            <MenuItem
              key={level.id}
              sx={{ textAlign: "center", fontSize: 14, padding: 1 }}
              value={level.id}>
              {`${level.nivel} - ${level.aaRef}`}
            </MenuItem>
          ))}
        </Select>
      </Box>
    ) : (
      level
    )
  );
}

export const Chapter = ({ params, setChapter }: any) => {
  const { value, api, id, chapters } = params;
  const edit = api.getCellMode(id, 'capitulo_id') === 'edit';
  const chapter =
    chapters.find((capitulo: ChapterProps) => capitulo.id === value)?.nome;

  const handleChangeChapter = (event: any) => {
    const capitulo = chapters.find((n: any) => n.id === event.target.value)?.nome;
    setChapter({ id: event.target.value, capitulo });
    api.setEditCellValue({ id, field: 'capitulo_id', value: event.target.value });
  }

  React.useEffect(() => {
    setChapter({ id: value, nome: chapter });
    api.setEditCellValue({ id, field: 'capitulo_id', chapter });
    // eslint-disable-next-line
  }, []);

  return (
    edit ? (
      <Box style={{
        width: "100%",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
        <Select
          defaultValue={value}
          style={{
            width: "90%",
            height: 40,
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 14,
          }}
          onChange={handleChangeChapter}
        >
          {chapters.map((capitulo: ChapterProps) => (
            <MenuItem
              key={capitulo.id}
              sx={{ textAlign: "center", fontSize: 14, padding: 1 }}
              value={capitulo.id}>
              {capitulo.nome}
            </MenuItem>
          ))}
        </Select>
      </Box>
    ) : (
      chapter
    )
  );
};

export const SchoolChoice = (params: any) => {
  const { value, api, id, field, schools } = params;
  // Schools { id: 1, escola: 'Vila Hípica' }, value ID
  // eslint-disable-next-line
  const school = schools.find((item: SchoolListProps) => item.id == value)?.id;

  const handleChangeSchool = (event: any) => {
    api.setEditCellValue({ id, field, value: event.target.value });
  }

  return (
    <Box sx={{ width: '100%', height: 40, textAlign: 'center' }}>
      <Select
        defaultValue={school}
        style={{
          width: '90%',
          height: 40,
          textAlign: 'left',
          fontSize: 14,
          color: '#000',
        }}
        onChange={handleChangeSchool}>
        {schools.map((item: SchoolListProps) => (
          <MenuItem
            key={item.id}
            sx={{ fontSize: 14, padding: 1 }}
            value={item.id}>
            {item.nome}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export const SelectRoom = ({ params }: any) => {
  const { value, api, id, rooms } = params;
  const edit = api.getCellMode(id, 'sala_id') === 'edit';
  // eslint-disable-next-line
  const sala = rooms.find((item: any) => item.id == value)?.sala;

  const handleChangeRoom = (event: any) => {
    api.setEditCellValue({ id, field: 'sala_id', value: event.target.value });
  }

  React.useEffect(() => {
    api.setEditCellValue({ id, field: 'sala_id', value });
    // eslint-disable-next-line
  }, []);

  return (
    edit ? (
      <Box style={{
        width: "100%",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
        <Select
          defaultValue={value}
          style={{
            width: "90%",
            height: 40,
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 14,
          }}
          onChange={handleChangeRoom}
        >
          {rooms.map((room: any) => (
            <MenuItem
              key={room.id}
              sx={{ textAlign: "center", fontSize: 14, padding: 1 }}
              value={room.id}>
              {room.sala}
            </MenuItem>
          ))}
        </Select>
      </Box>
    ) : (
      sala
    )
  );
};

export const SelectTeacher = ({ params }: any) => {
  const { value, api, id, teachers } = params;
  const edit = api.getCellMode(id, 'usuario_id') === 'edit';
  // eslint-disable-next-line
  const teacher = teachers.find((item: any) => item.usuario_id == value)?.nome;

  const handleChangeTeacher = (event: any) => {
    api.setEditCellValue({ id, field: 'usuario_id', value: event.target.value });
  }

  React.useEffect(() => {
    api.setEditCellValue({ id, field: 'usuario_id', value });
    // eslint-disable-next-line
  }, []);

  return (
    edit ? (
      <Box style={{
        width: "100%",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
        <Select
          defaultValue={value}
          style={{
            width: "90%",
            height: 40,
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 14,
          }}
          onChange={handleChangeTeacher}
        >
          {teachers.map((teach: any) => (
            <MenuItem
              key={teach.usuario_id}
              sx={{ textAlign: "center", fontSize: 14, padding: 1 }}
              value={teach.usuario_id}>
              {teach.nome}
            </MenuItem>
          ))}
        </Select>
      </Box>
    ) : (
      teacher
    )
  );
};

export const MultipleSelect = ({ params }: any) => {
  /* items & field params */
  const { value, api, id, items, field, nome } = params;
  const edit = api.getCellMode(id, field) === 'edit';
  const [data, setData] = React.useState<number[]>((typeof value === 'number' ? [value] : value) || []);

  const handleChangeOption = (event: any) => {
    const { target: { value } } = event;

    setData(
      typeof value === 'string'
        ? value.split(',').map((item: string) => Number(item))
        : value
    );

    api.setEditCellValue({ id, field, value: data });
  }

  React.useEffect(() => {
    if (data) api.setEditCellValue({ id, field, value: data });
    // eslint-disable-next-line
  }, [data]);

  return (
      <Box style={{
        width: "100%",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
        {value && (
          <Select
            value={data}
            multiple
            style={{
              width: "90%",
              height: 60,
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 14,
            }}
            renderValue={(selected: any) => {
              const first = items.find((item: any) => item.id === selected[0]);
              return (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  <Box
                    key={value}
                    sx={{
                      color: '#000',
                      padding: .3,
                      width: '100%',
                    }}>
                    {selected.length < 2
                      ? first?.[nome]
                      : selected.length > 1
                        ? first?.[nome] + '...'
                        : first?.[nome]
                    }
                  </Box>
              </Box>
            )}}
            onChange={handleChangeOption}
          >
            {items.map((item: any) => (
              <MenuItem
                key={item.id}
                disabled={!edit}
                sx={{
                  textAlign: "left",
                  fontSize: 14,
                  padding: 1,
                  display: 'flex',
                  justifyContent: 'left',
                }}
                value={item.id}>
                  {data.length > 0 && data.includes(item.id)
                    ? <CheckBox style={{ marginRight: 5 }} />
                    : <CheckBoxOutlineBlank style={{ marginRight: 5 }} />}
                {nome === 'nivel'
                  ? `${item?.nivel} - ${item?.aaRef}`
                  : item?.[nome]}
              </MenuItem>
            ))}
          </Select>
        )}
      </Box>
    );
};

export const MultipleChapters = ({ params }: any) => {
  /* items & field params */
  const { value, api, id, items, field, nome } = params;
  const edit = api.getCellMode(id, field) === 'edit';
  const [data, setData] = React.useState<number[]>((typeof value === 'number' ? [value] : value) || []);

  const handleChangeOption = (event: any) => {
    const { target: { value } } = event;

    setData(
      typeof value === 'string'
        ? value.split(',').map((item: string) => Number(item))
        : value
    );

    api.setEditCellValue({ id, field, value: data });
  }

  React.useEffect(() => {
    if (data) api.setEditCellValue({ id, field, value: data });
    // eslint-disable-next-line
  }, [data]);

  return (
      <Box style={{
        width: "100%",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
        {value && (
          <Select
            value={data}
            multiple
            style={{
              width: "90%",
              height: 60,
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 14,
            }}
            renderValue={(selected: any) => {
              const first = items.find((item: any) => item.value === selected[0]);
              return (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  <Box
                    key={value}
                    sx={{
                      color: '#000',
                      padding: .3,
                      width: '100%',
                    }}>
                    {selected.length < 2
                      ? first?.[nome]
                      : selected.length > 1
                        ? first?.[nome] + '...'
                        : first?.[nome]
                    }
                  </Box>
              </Box>
            )}}
            onChange={handleChangeOption}
          >
            {items.map((item: any) => (
              <MenuItem
                key={item.id}
                disabled={!edit}
                sx={{
                  textAlign: "left",
                  fontSize: 14,
                  padding: 1,
                  display: 'flex',
                  justifyContent: 'left',
                }}
                value={item.value}>
                  {data.length > 0 && data.includes(item.value)
                    ? <CheckBox style={{ marginRight: 5 }} />
                    : <CheckBoxOutlineBlank style={{ marginRight: 5 }} />}
                {item?.[nome]}
              </MenuItem>
            ))}
          </Select>
        )}
      </Box>
    );
};

// export const MultipleChapters = ({ params, setMultipleChapters }: any) => {
//   const { value, api, id, chapters } = params;
//   const edit = api.getCellMode(id, 'capitulo_id') === 'edit';
//   const [data, setData] = React.useState<number[]>(value || []);

//   const altura = 60;
//   const topo = 8;
//   const MenuProps = {
//     PaperProps: {
//       style: {
//         maxHeight: altura * 4.5 + topo,
//         width: 250,
//       },
//     },
//   }

//   const handleChangeChapter = (event: any) => {
//     const { target : { value } } = event;
//     setData(
//       typeof value === 'string'
//         ? value.split(',').map((item: string) => Number(item))
//         : value
//     );
//   }

//   React.useEffect(() => {
//     setMultipleChapters(data);
//     api.setEditCellValue({ id, field: 'capitulo_id', value: data });
//     // eslint-disable-next-line
//   }, [data]);

//   return (
//     edit ? (
//       <Box style={{
//         width: "100%",
//         textAlign: "center",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//       }}>
//         <Select
//           // defaultValue={chapter}
//           value={data}
//           id='select-multiple-chapters'
//           multiple
//           // input={<OutlinedInput id="select-multiple-chapters" />}
//           style={{
//             width: "100%",
//             height: 60,
//             textAlign: "center",
//             alignItems: "center",
//             justifyContent: "center",
//             fontSize: 14,
//           }}
//           renderValue={(selected: any) => {
//             console.log('Selected:', selected);
//             return (
//             <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
//               {selected.map((value: any) => (
//                 <Box
//                   key={value}
//                   sx={{
//                     backgroundColor: 'primary.main',
//                     color: 'white',
//                     padding: .8,
//                     borderRadius: 1.5,
//                   }}>
//                   {chapters.find((capitulo: ChapterProps) => capitulo.id === value)?.nome}
//                 </Box>
//               ))}
//             </Box>
//           )}}
//           onChange={handleChangeChapter}
//           MenuProps={MenuProps}
//         >
//           {chapters.map((capitulo: ChapterProps) => (
//             <MenuItem
//               key={capitulo.id}
//               sx={{ textAlign: "center", fontSize: 14, padding: 1 }}
//               value={capitulo.id}>
//               {capitulo.nome}
//             </MenuItem>
//           ))}
//         </Select>
//       </Box>
//     ) : (
//       value
//     )
//   );
// }

export const WeekChoice = ({ params }: any) => {
  const { value, api, id } = params;
  const edit = api.getCellMode(id, 'dia_semana') === 'edit';
  const weeks = [
    'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado', 'Domingo'
  ];

  const handleChangeWeek = (event: any) => {
    api.setEditCellValue({ id, field: 'dia_semana', value: event.target.value });
  }

  return (
    edit ? (
      <Box style={{
        width: "100%",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
        <Select
          defaultValue={value}
          style={{
            width: "90%",
            height: 40,
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 14,
          }}
          onChange={handleChangeWeek}
        >
          {weeks.map((week, index) => (
            <MenuItem
              key={index}
              sx={{ textAlign: "center", fontSize: 14, padding: 1 }}
              value={index + 1}>
              {week}
            </MenuItem>
          ))}
        </Select>
      </Box>
    ) : (
      weeks[value - 1]
    )
  );
}

export const EditHours = ({ params }: any) => {
  const { value, api, id } = params;
  const edit = api.getCellMode(id, 'horario') === 'edit';

  const handleChangeHour = (event: any) => {
    api.setEditCellValue({ id, field: 'horario', value: event.target.value });
  }

  return (
    edit ? (
      <Box style={{
        width: "100%",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
        <input
          type="time"
          defaultValue={value.slice(0, 5)}
          onChange={handleChangeHour}
          style={{
            width: "90%",
            height: 40,
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 14,
          }} />
      </Box>
    ) : (
      value.slice(0, 5)
    )
  );
}

// interface RitchTextModalProps {
//   text: EditorState;
//   setText: React.Dispatch<React.SetStateAction<EditorState>>;
// }

// export const RitchTextModal = ({ text, setText }: RitchTextModalProps) => {
//   const theme = createTheme({
//     spacing: 4,
//   });
//   let editorContent = text;

//   Swal.fire({
//     title: 'Editar Texto',
//     html: '<div id="rich-text-editor"></div>',
//     showCancelButton: true,
//     confirmButtonText: 'Salvar',
//     reverseButtons: true,
//     didOpen: () => {
//       const editorContainer = document.getElementById('rich-text-editor');
//       if (editorContainer) {
//         const editorDiv = document.createElement('div');
//         editorDiv.id = 'rich-text-editor-content';
//         editorContainer.appendChild(editorDiv);

//         ReactDOM.render(
//           <ThemeProvider theme={theme}>
//             <MUIRichTextEditor
//               label="Digite o texto aqui..."
//               defaultValue={JSON.stringify(convertToRaw(text.getCurrentContent()))}
//               onChange={(state) => {
//                 editorContent = EditorState.createWithContent(state.getCurrentContent());
//               }}
//               controls={[
//                 'title',
//                 'bold',
//                 'italic',
//                 'underline',
//                 'strikethrough',
//                 'undo',
//                 'redo',
//                 'numberList',
//                 'bulletList',
//               ]}
//             />
//           </ThemeProvider>,
//           editorDiv
//         );
//       }
//     },
//     willOpen: () => {
//       Swal.update({
//         html: '',
//       });
//     },
//     preConfirm: () => {
//       console.log('Conteúdo:', editorContent);
//       if (editorContent) setText(editorContent);
//       return true;
//     },
//   });
// };