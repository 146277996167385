import React from "react";
import AccountMenu from "../AccountMenu";
import { Box, Select, SelectChangeEvent, Typography } from "@mui/material";
import { SearchField } from "../../Components/Search";
import CrudStudents from "./components/EditableStudents";
import { SkeletonGrid } from "../../Components/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../../Common/Helpers/Toast";
import Failure from "../../Components/Failure";
import StudentsOperations from "../../InfraStructure/Students/Operations";
import { StudentProps } from "./components/types";
import RoomsOperations from "../../InfraStructure/Rooms/RoomsOperations";

export default function Students() {
  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [filteredData, setFilteredData] = React.useState<any[]>();
  const [studentsByRoom, setStudentsByRoom] = React.useState<any[]>([]);
  // eslint-disable-next-line
  const [defaultValueRoom, setDefaultValueRoom] = React.useState<number | string>('');

  const dispatch = useDispatch();
  const { IsLoading, HasError, Data } = useSelector(
    (state: any) => state.students
  );
  const {
    Data: roomsData,
    // IsLoading: roomsIsLoading,
    // HasError: roomsHasError,
  } = useSelector((state: any) => state.rooms);

  const openToast = (message: string) => {
    setMessage(message);
    setOpen(true);
  }

  const getStudents = async () => {
    try {
      await dispatch<any>(StudentsOperations.getStudents());
    } catch (error: string | unknown) {
      console.log('Erro buscando alunos: ', error);
      openToast('Erro na rede. Tente novamente...');
    }
  };

  const getRooms = async () => {
    try {
      dispatch<any>(RoomsOperations.getRooms());
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateRoom = async (newRow: StudentProps) => {
    try {
      const response = await dispatch<any>(StudentsOperations.updateStudent(newRow));
      return response;
    } catch (error: string | unknown) {
      console.log('Erro atualizando aluno: ', error);
      openToast('Erro na rede. Tente novamente...');
    }
  }

  const handleUseFilterByRoom = () => {
    if (Data && Data.length > 0) {
      let filter = [];
      Data.map((student: StudentProps) => {
        if (student.idiomas_sala) {
          filter.push(student);
        }
        return filter;
      });

      const uniqueFilter = filter.filter((v, i, a) => a.findIndex(t => (t.idiomas_sala === v.idiomas_sala)) === i);

      setStudentsByRoom(uniqueFilter);
    }
  };

  const nomeSala = (id: number) => {
    if (roomsData && roomsData.length > 0) {
      // eslint-disable-next-line
      const sala = roomsData.find((room: any) => room.id == id);
      return sala?.sala;
    }
  };

  const handleChangeRoom = (event: SelectChangeEvent<string | number>) => {
    const value = event.target.value as number | string;
    if (value === '') {
      setFilteredData(Data);
    } else {
      const filter = Data.filter((student: StudentProps) => student.idiomas_sala === value);
      setFilteredData(filter);
    }
  };

  React.useEffect(() => {
    getStudents();
    getRooms();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (Data) {
      setFilteredData(Data);
      handleUseFilterByRoom();
    }
    // eslint-disable-next-line
  }, [Data]);

  if (HasError) {
    return (
      <React.Fragment>
        <Toast message={message} open={open} setclose={setOpen} severity="error" />
        <Failure message='Erro ao buscar usuários' onClick={getStudents} />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <AccountMenu />

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'flex-start' }}>
        <Box sx={{ width: "95%", margin: 'auto', padding: 2 }}>
          <Typography
            variant="h5"
            sx={{ color: "#000", fontWeight: "bold", marginTop: 2, marginBottom: 2 }}>
            Alunos
          </Typography>
          
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              marginBottom: 3,
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                marginBottom: 3,
              }}>
              <SearchField
                width={270}
                placeHolder="Buscar por nome, e-mail"
                data={Data}
                setSearch={setFilteredData}
                columnsToSearch={['nome', 'email']}
              />
            </Box>
            <Select
              sx={{ marginLeft: 2, width: 200, height: 50, marginTop: 5 }}
              native
              defaultValue={defaultValueRoom}
              onChange={handleChangeRoom}
              inputProps={{
                name: 'sala',
                id: 'sala',
              }}
            >
              <option value="">Todas as salas</option>
              {studentsByRoom.length > 0 && studentsByRoom.map((student: StudentProps) => (
                <option 
                  key={student.num_aluno}
                  value={student.idiomas_sala}>
                  {nomeSala(student.idiomas_sala)}
                </option>
              ))}
            </Select>
          </Box>
          
          {IsLoading
            ? <SkeletonGrid />
            : <CrudStudents
                data={filteredData}
                onSave={handleUpdateRoom}
              />
          }
        </Box>
      </Box>
    </React.Fragment>
  );
}