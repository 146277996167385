import ExercisesTypes from './Types';

const ExercisesActions = {
  SetLoading: () => ({
    type: ExercisesTypes.SET_EXERCISES_LOADING
  }),
  SetFailure: () => ({
    type: ExercisesTypes.SET_EXERCISES_FAILURE
  }),
  SetSuccess: (payload: any, type: string) => ({
    type: ExercisesTypes.SET_EXERCISES_SUCCESS,
    payload: {[type]: payload},
  }),
}

export default ExercisesActions;