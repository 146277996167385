import Api from "../Helpers/Api";

class SchoolService {
  getSchools() {
    return Api.get(`/escolas`);
  }

  saveSchool(data: any) {
    return Api.get(`/escolas/create`, data);
  }

  editSchool(data: any) {
    return Api.get(`/escolas/update`, data);
  }
  
  deleteSchool(id: number) {
    return Api.get(`/escolas/delete/${id}`);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new SchoolService();