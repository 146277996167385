import React from "react";
import AccountMenu from "../AccountMenu";
import { Box, Typography } from "@mui/material";
import { SearchField } from "../../Components/Search";
import CrudTeachers from "./components/EditableTeachers";
import { SkeletonGrid } from "../../Components/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../../Common/Helpers/Toast";
import Failure from "../../Components/Failure";
import TeachersOperations from "../../InfraStructure/Teachers/Operations";

export default function Teachers() {
  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [filteredData, setFilteredData] = React.useState<any[]>();

  const dispatch = useDispatch();
  const { IsLoading, HasError, Data } = useSelector(
    (state: any) => state.teachers
  );

  const openToast = (message: string) => {
    setMessage(message);
    setOpen(true);
  }

  const getTeachers = async () => {
    try {
      await dispatch<any>(TeachersOperations.getTeachers());
    } catch (error: string | unknown) {
      console.log('Erro buscando professores: ', error);
      openToast('Erro na rede. Tente novamente...');
    }
  };

  React.useEffect(() => {
    getTeachers();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (Data) {
      setFilteredData(Data);
    }
  }, [Data]);

  if (HasError) {
    return (
      <React.Fragment>
        <Toast message={message} open={open} setclose={setOpen} severity="error" />
        <Failure message='Erro ao buscar professores' onClick={getTeachers} />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <AccountMenu />

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'flex-start' }}>
        <Box sx={{ width: "95%", margin: 'auto', padding: 2 }}>
          <Typography
            variant="h5"
            sx={{ color: "#000", fontWeight: "bold", marginTop: 2, marginBottom: 2 }}>
            Professores
          </Typography>
          
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              marginBottom: 3,
            }}>
            <SearchField
              width={270}
              placeHolder="Buscar por nome, e-mail"
              data={Data}
              setSearch={setFilteredData}
              columnsToSearch={['nome', 'email']}
            />
          </Box>
          
          {IsLoading ? <SkeletonGrid /> : <CrudTeachers data={filteredData} />}
        </Box>
      </Box>
    </React.Fragment>
  );
}