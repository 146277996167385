import { UnknownAction } from "redux";
import FlashCardsActions from "./Actions";
import FlashCardsService from "../../Common/Services/FlashCardsService";
import { SaveFlashCardProps } from "../../Page/FlashCards/components/types";
import { ResponseProps } from "../../@types/types";

interface Dispatch {
  (action: UnknownAction): void;
}

interface FlashCardsOperationsType {
  getFlashCards: () => (dispatch: Dispatch) => Promise<void>;
  createFlashCard: (data: any) => (dispatch: Dispatch) => Promise<ResponseProps>;
  updateFlashCard: (data: any) => (dispatch: Dispatch) => Promise<ResponseProps>;
  deleteFlashCard: (id: number) => (dispatch: Dispatch) => Promise<ResponseProps>;
}

const FlashCardsOperations: FlashCardsOperationsType = {
  getFlashCards: () => async (dispatch: Dispatch): Promise<void> => {
    dispatch(FlashCardsActions.SetLoading());
    try {
      const response = await FlashCardsService.getFlashCards();
      const responseData = response as { data: { data: SaveFlashCardProps } };
      dispatch(FlashCardsActions.SetSuccess(responseData.data.data));
    } catch (error: string | unknown) {
      console.log('Erro buscando flashCards: ', error);
      dispatch(FlashCardsActions.SetFailure());
    }
  },

  createFlashCard: (data: any) => async (): Promise<ResponseProps> => {
    try {
      const response = await FlashCardsService.createFlashCard(data);
      return response.data as unknown as ResponseProps;
    } catch (error) {
      console.log('Erro salvando flashCard: ', error);
      return { message: 'Erro na rede. Tente novamente...', status: 'error' };
    }
  },

  updateFlashCard: (data: any) => async (): Promise<ResponseProps> => {
    try {
      const response = await FlashCardsService.updateFlashCard(data);
      return response.data as unknown as ResponseProps;
    } catch (error) {
      console.log('Erro atualizando flashCard: ', error);
      throw new Error(error);
    }
  },

  deleteFlashCard: (id: number) => async (): Promise<ResponseProps> => {
    try {
      await FlashCardsService.deleteFlashCard(id);
      return { message: 'FlashCard deletado com sucesso.', status: 'success' };
    } catch (error) {
      console.log('Erro deletando flashCard: ', error);
      throw new Error(error);
    }
  }
}

export default FlashCardsOperations;