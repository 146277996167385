import { UnknownAction } from "redux";
import BooksActions from "./Actions";
import BooksService from "../../Common/Services/BooksService";
import { ResponseProps } from "../../@types/types";

interface Dispatch {
  (action: UnknownAction): void;
}

interface BooksOperationsType {
  getBooks: () => (dispatch: Dispatch) => Promise<void>;
  createBook: (formData: FormData) => (dispatch: Dispatch) => Promise<ResponseProps>;
  updateBook: (formData: FormData) => (dispatch: Dispatch) => Promise<ResponseProps>;
  deleteBook: (id: number) => (dispatch: Dispatch) => Promise<ResponseProps>;
}

const BooksOperations: BooksOperationsType = {
  getBooks: () => async (dispatch: Dispatch): Promise<void> => {
    dispatch(BooksActions.SetLoading());
    try {
      const response = await BooksService.getBooks();
      const data = (response as { data: { data: any } }).data;
      dispatch(BooksActions.SetSuccess(data.data));
    } catch (error: string | unknown) {
      console.log('Erro buscando flashCards: ', error);
      dispatch(BooksActions.SetFailure());
    }
  },

  createBook: (formData: FormData) => async (dispatch: Dispatch): Promise<ResponseProps> => {
    try {
      const response = await BooksService.createBook(formData);
      return response.data as unknown as ResponseProps;
    } catch (error: string | unknown) {
      console.log('Erro salvando livro: ', error);
      return { status: 'error', message: 'Erro ao salvar o livro' };
    }
  },

  updateBook: (formData: FormData) => async (dispatch: Dispatch): Promise<ResponseProps> => {
    try {
      const response = await BooksService.updateBook(formData);
      return response.data as unknown as ResponseProps;
    } catch (error: string | unknown) {
      console.log('Erro atualizando livro: ', error);
      return { status: 'error', message: 'Erro ao atualizar o livro' };
    }
  },

  deleteBook: (id: number) => async (dispatch: Dispatch): Promise<ResponseProps> => {
    try {
      await BooksService.deleteBook(id);
      return { message: 'Livro apagado com sucesso.', status: 'success' };
    } catch (error: string | unknown) {
      console.log('Erro apagando livro: ', error);
      return { status: 'error', message: 'Erro ao apagar o livro' };
    }
  }
}

export default BooksOperations;