import HomeworksTypes from './Types';

const HomeworksActions = {
  SetLoading: () => ({
    type: HomeworksTypes.SET_HOMEWORKS_LOADING
  }),
  SetFailure: () => ({
    type: HomeworksTypes.SET_HOMEWORKS_FAILURE
  }),
  SetSuccess: (payload: any, type: string) => ({
    type: HomeworksTypes.SET_HOMEWORKS_SUCCESS,
    payload: {[type]: payload},
  }),
}

export default HomeworksActions;