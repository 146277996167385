import AdministratorsTypes from './Types';

const AdministratorsActions = {
  SetLoading: () => ({
    type: AdministratorsTypes.SET_ADMINISTRATORS_LOADING
  }),
  SetFailure: () => ({
    type: AdministratorsTypes.SET_ADMINISTRATORS_FAILURE
  }),
  SetSuccess: (payload: any) => ({
    type: AdministratorsTypes.SET_ADMINISTRATORS_SUCCESS,
    payload
  }),
}

export default AdministratorsActions;