import { ResponseProps } from "../../@types/types";
import QuestionsService from "../../Common/Services/QuestionsService";
import { QuestionProps } from "../../Page/Questions/components/types";
import { AppDispatch } from "../store";
import QuestionsActions from "./Actions";

interface QuestionsOperationsType {
  getQuestions: () => (dispatch: AppDispatch) => Promise<void>;
  saveQuestion: (question: any) => (dispatch: AppDispatch) => Promise<ResponseProps>;
  updateQuestion: (question: any) => (dispatch: AppDispatch) => Promise<ResponseProps>;
  deleteQuestion: (id: number) => (dispatch: AppDispatch) => Promise<ResponseProps>;
}

const QuestionsOperations: QuestionsOperationsType = {
  getQuestions: () => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(QuestionsActions.SetLoading());
    try {
      const response = await QuestionsService.getQuestions();
      const responseData = response as { data: { data: QuestionProps } };
      dispatch(QuestionsActions.SetSuccess(responseData.data));
    } catch (error: string | unknown) {
      console.log('Erro buscando as questões: ', error);
      dispatch(QuestionsActions.SetFailure());
    }
  },

  saveQuestion: (question: any) => async (dispatch: AppDispatch): Promise<ResponseProps> => {
    try {
      const response = await QuestionsService.saveQuestion(question);
      return response.data as unknown as ResponseProps;
    } catch (error: string | unknown) {
      console.log('Erro salvando a questão: ', error);
      return { message: 'Erro na rede. Tente novamente...', status: 'error' };
    }
  },

  updateQuestion: (question: any) => async (dispatch: AppDispatch): Promise<ResponseProps> => {
    try {
      const response = await QuestionsService.updateQuestion(question);
      return response.data as unknown as ResponseProps;
    } catch (error: string | unknown) {
      console.log('Erro atualizando a questão: ', error);
      return { message: 'Erro na rede. Tente novamente...', status: 'error' };
    }
  },

  deleteQuestion: (id: number) => async (dispatch: AppDispatch): Promise<ResponseProps> => {
    try {
      await QuestionsService.deteleQuestion(id);
      return { message: 'Questão deletada com sucesso.', status: 'success' };
    } catch (error) {
      console.log('Erro deletando a questão: ', error);
      return { message: 'Erro na rede. Tente novamente...', status: 'error' };
    }
  }
}

export default QuestionsOperations;