import LevelsService from "../../Common/Services/LevelsService";
import { AppDispatch } from "../store";
import LevelsActions from "./LevelsActions";

interface LevelsOperationsType {
  getLevels: () => (dispatch: AppDispatch) => Promise<void>;
}

const LevelsOperations: LevelsOperationsType = {
  getLevels: () => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(LevelsActions.SetLoading());
    try {
      const response = await LevelsService.getLevels();
      const data = (response as { data: { data: any } }).data;
      dispatch(LevelsActions.SetSuccess(data));
    } catch (error: string | unknown) {
      console.log('Erro buscando níveis: ', error);
    }
  }
}

export default LevelsOperations;