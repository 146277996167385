import React, { useEffect } from "react";
import AccountMenu from "../AccountMenu";
import { Box, Typography } from "@mui/material";
import { SkeletonGrid } from "../../Components/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../../Common/Helpers/Toast";
import Failure from "../../Components/Failure";
import ExercisesOperations from "../../InfraStructure/Exercises/ExercisesOperations";
import { useNavigate, useParams } from "react-router-dom";
import FormExercises from "./components/FormExercises";
import HomeworksOperations from "../../InfraStructure/Homeworks/HomeworksOperations";
import { ExercisesProps } from "../Exercises/components/types";
import Swal from "sweetalert2";

export default function Exercises() {
  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [filteredData, setFilteredData] = React.useState<ExercisesProps>();
  const [types, setTypes] = React.useState<any[]>();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { IsLoading, HasError, Data } = useSelector(
    (state: any) => state.exercises
  );

  const Homeworks = useSelector(
    (state: any) => state.homeworks
  )?.Data;

  let { id, tarefa_id } = useParams();

  const handleSave = async () => {
    try {
      const response = await dispatch<any>(ExercisesOperations.createExercises(filteredData));
      if(response.status === 'success'){
        Swal.fire({
          title: "Sucesso!",
          text: response.message,
          icon: "success"
        }).then((result) => {
          navigate(-1);
        });
      }
    } catch (error: string | unknown) {
      console.log('Erro salvando capítulo: ', error);
      Swal.fire({
          title: "Erro!",
          text: 'Erro na rede. Tente novamente...',
          icon: "error"
        });
    }
  }


  const handleUpdate = async () => {
    try {
      const response = await dispatch<any>(ExercisesOperations.updateExercises(filteredData));
      if(response.status === 'success'){
        Swal.fire({
          title: "Sucesso!",
          text: response.message,
          icon: "success"
        }).then((result) => {
          navigate(-1);
        });
      }
    } catch (error: string | unknown) {
      console.log('Erro salvando capítulo: ', error);
      Swal.fire({
          title: "Erro!",
          text: 'Erro na rede. Tente novamente...',
          icon: "error"
        });
    }
  }

  const openToast = (message: string) => {
    setMessage(message);
    setOpen(true);
  }

  const getExercises = async () => {
    try {
      await dispatch<any>(HomeworksOperations.getHomework(parseInt(tarefa_id)));
      if(id){
        await dispatch<any>(ExercisesOperations.getExercise(id));        
      }
    } catch (error: string | unknown) {
      console.log('Erro buscando exercício: ', error);
      openToast('Erro na rede. Tente novamente...');
    }
  };

  const getTypes = async () => {
    if(Homeworks?.homeworks?.data?.tipo_tarefa_id){
      try {
        await dispatch<any>(ExercisesOperations.getTypesByHomework(Homeworks?.homeworks?.data?.tipo_tarefa_id));
      } catch (error: string | unknown) {
        console.log('Erro buscando tarefas: ', error);
        openToast('Erro na rede. Tente novamente...');
      }
    }
  }

  useEffect(() => {
    if (Data) {
      setFilteredData({...Data?.exercises?.data, tarefa_id: parseInt(tarefa_id)});
      setTypes((Data?.types?.data || []).map((type)=>({value: type.id, label: type.tipo}))||[]);
    }
    // eslint-disable-next-line
  }, [Data]);

  useEffect(()=> {
    if(Homeworks){
      getTypes();
    }
    // eslint-disable-next-line
  }, [Homeworks])

  useEffect(() => {
    getExercises();
    // eslint-disable-next-line
  }, []);

  /* const SearchSelect = (data: any) => {
    const [searchValue, setSearchValue] = React.useState<string>('');

    const handleChange = (event: SelectChangeEvent) => {
      setSearchValue(event.target.value as string);
    };

    return (
      <FormControl variant="outlined" sx={{ minWidth: 150 }}>
        <InputLabel id="searchSelect">Capítulo</InputLabel>
        <Select
          labelId="selectSelect"
          id="selectSelect"
          label="Capítulo"
          value={searchValue}
          onChange={handleChange}
        >
          <MenuItem value=""><em>Nenhum</em></MenuItem>
          {(data.data || []).map((item: any, index: number) => (
            <MenuItem key={index} value={item}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  } */

  if (HasError) {
    return (
      <React.Fragment>
        <Toast message={message} open={open} setclose={setOpen} severity="error" />
        <Failure message='Erro ao buscar tarefas' onClick={getExercises} />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <AccountMenu />

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'flex-start' }}>
        <Box sx={{ width: "95%", margin: 'auto', padding: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center'}}>
            <Box sx={{width: '100%', maxWidth: 1500}}>
              <Typography
                variant="h5"
                sx={{ color: "#000", fontWeight: "bold", marginTop: 2, marginBottom: 2, }}>
                Exercícios
              </Typography>
            </Box>
            <Box sx={{width: '100%', maxWidth: 1500}}>
              {IsLoading ? <SkeletonGrid /> : <FormExercises data={filteredData} setData={setFilteredData} types={types} onUpdate={handleUpdate} onSave={handleSave} />}
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}