import QestionsTypes from './Types';

const QuestionsActions = {
  SetLoading: () => ({
    type: QestionsTypes.SET_QUESTIONS_LOADING
  }),
  SetFailure: () => ({
    type: QestionsTypes.SET_QUESTIONS_FAILURE
  }),
  SetSuccess: (payload: any) => ({
    type: QestionsTypes.SET_QUESTIONS_SUCCESS,
    payload
  }),
}

export default QuestionsActions;