import FlashCardsType from './Types';

const FlashCardsActions = {
  SetLoading: () => ({
    type: FlashCardsType.SET_FLASHCARDS_LOADING
  }),
  SetFailure: () => ({
    type: FlashCardsType.SET_FLASHCARDS_FAILURE
  }),
  SetSuccess: (payload: any) => ({
    type: FlashCardsType.SET_FLASHCARDS_SUCCESS,
    payload
  }),
}

export default FlashCardsActions;