import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import * as Modules from './modules';
import ProtectedRoute from './ProtectedRoute';

const ApplicationRoute = ({ history, match, selectedPortal }: any) => {
  return (
    <Router>
      <div style={{ display: "flex", flexDirection: "row", height: "100vh" }}>
        <Modules.Menu />
        <div style={{ flex: 1, height: "100vh", overflowY: "scroll" }}>
          <Routes>
            <Route path="/unidades-escolares" element={<ProtectedRoute Component={Modules.Schools} />} />
            <Route path="/salas" element={<ProtectedRoute Component={Modules.Rooms} />} />
            <Route path="/salas-horarios/:id" element={<ProtectedRoute Component={Modules.RoomHours} />} />
            <Route path="/capitulos" element={<ProtectedRoute Component={Modules.Chapters} />} />
            <Route path="/tarefas" element={<ProtectedRoute Component={Modules.Homeworks} />} />
            <Route path="/tarefas/:tarefa_id" element={<ProtectedRoute Component={Modules.Exercises} />} />
            <Route path="/tarefas/:tarefa_id/exercicio" element={<ProtectedRoute Component={Modules.ExercisesEdit} />} />
            <Route path="/tarefas/:tarefa_id/exercicio/:id" element={<ProtectedRoute Component={Modules.ExercisesEdit} />} />
            <Route path="/aulas-de-speaking" element={<ProtectedRoute Component={Modules.Speaking} />} />
            <Route path="/jogos" element={<ProtectedRoute Component={Modules.Jogos} />} />
            <Route path="/questions" element={<ProtectedRoute Component={Modules.Questions} />} />
            <Route path="/flash-cards" element={<ProtectedRoute Component={Modules.FlashCards} />} />
            <Route path="/videos" element={<ProtectedRoute Component={Modules.Videos} />} />
            <Route path="/books" element={<ProtectedRoute Component={Modules.EtapaDigital} />} />
            <Route path="/documentos" element={<ProtectedRoute Component={Modules.Documents} />} />
            <Route path="/feedback-de-jogos" element={<ProtectedRoute Component={Modules.Feedback} />} />
            <Route path="/configuracoes" element={<ProtectedRoute Component={Modules.Settings} />} />
            <Route path="/user" element={<ProtectedRoute Component={Modules.Administrator} />} />
            <Route path="/professores" element={<ProtectedRoute Component={Modules.Teachers} />} />
            <Route path="/alunos" element={<ProtectedRoute Component={Modules.Students} />} />
            <Route path="/logout" Component={Modules.Logout} />
            <Route path="*" element={<ProtectedRoute Component={Modules.Schools} />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default ApplicationRoute;
