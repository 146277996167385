import CoordinatorsTypes from './Types';

const Coordinators = {
  SetLoading: () => ({
    type: CoordinatorsTypes.SET_COORDINATORS_LOADING
  }),
  SetFailure: () => ({
    type: CoordinatorsTypes.SET_COORDINATORS_FAILURE
  }),
  SetSuccess: (payload: any) => ({
    type: CoordinatorsTypes.SET_COORDINATORS_SUCCESS,
    payload
  }),
}

export default Coordinators;