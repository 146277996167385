import { Box, Button, CircularProgress, LinearProgress, Typography } from "@mui/material"
import theme from "../../Theme";
import React from "react";
import { useParams } from "react-router-dom";
import { CheckCircle, Error } from "@mui/icons-material";
import Swal from "sweetalert2";
import LoginOperations from "../../InfraStructure/Login/LoginOperations";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../InfraStructure/store";
import { userData } from "../../Components/User";
const students = require('../../assets/media/students.png');

type VerifyProps = {
  status: 'pending' | 'success' | 'error';
  message: string;
  value?: number;
}

interface CardProps {
  title: string;
  value: number;
  status?: 'error' | 'success' | 'pending';
}

type ProgressProps = {
  progress: VerifyProps
}

const messageEtapa =
 `Não é possível acessar o <strong>Portal Idiomas</strong> diretamente. Faça login nos portais credenciados ou no aplicativo <strong>Hub Sistema ETAPA</strong> para ter acesso.`

const ShowModal = () => (
  Swal.fire({
    title: "Sem acesso",
    icon: "warning",
    html: messageEtapa,
    showCancelButton: false,
    confirmButtonText: "ok",
  })
)

const Login = () => {
  const backImage = require('../../assets/media/background.png');
  const logo = require('../../assets/media/logo.png');
  const [progressHash, setProgressHash] = React.useState<VerifyProps>({ status: "pending", message: "", value: 0 });
  const [progressEmail, setProgressEmail] = React.useState<VerifyProps>({ status: "pending", message: "", value: 0 });
  const [progressAccess, setProgressAccess] = React.useState<VerifyProps>({ status: "pending", message: "", value: 0 });
  const [userMessage, setUserMessage] = React.useState('Verificando dados');
  const [loading, setLoading] = React.useState(true);
  const { token } = useParams();
  const { HasError } = useSelector((state: any) => state.user);
  const dispatch = useDispatch<AppDispatch>();

  const handleUserAccess = () => {
    setProgressAccess({ status: 'success', message: 'Acesso ok', value: 100 });
    setUserMessage('Acesso ok');
    window.location.reload();
  }

  const CardError = ({ progress }: ProgressProps) => (
    <Box sx={{ textAlign: "center" }}>
      <Button
        variant="contained"
        color={progress.status !== 'pending' ? progress.status : 'success'}
        startIcon={<Error fontSize="small" />}
        onClick={ShowModal}
      >
        {progress.message}
      </Button>
    </Box>
  );

  const Card = ({ title, value, status }: CardProps) => {
    return (
      <div style={{
        width: 350,
        paddingInline: 10,
        backgroundColor: "#FFF",
        borderRadius: 10,
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        margin: 15,
      }}>
        <Typography variant="subtitle1" color={theme.palette.primary.main}>
          <strong>{title}</strong>
        </Typography>
        <LinearProgress variant="determinate" value={value} sx={{ width: "70%", }} />
        <Typography variant="subtitle2" color={theme.palette.primary.main}>
          {status === 'pending'
            ? `${value}%`
            : (
            <Box sx={{ mt: 1.2 }}>
              {status === 'error'
                ? <Error fontSize="small" color="error" sx={{ paddingBottom: -1 }} />
                : <CheckCircle fontSize="small" color="primary" sx={{ paddingBottom: -1 }} />
              }
            </Box>
          )}
        </Typography>
      </div>
    );
  };
  
  const verifyHash = async () => {
    setProgressHash({ status: "pending", message: "Verificando hash", value: 20 });
    setLoading(true);
    setUserMessage("Verificando hash");

    if (!token) {
      setProgressHash({ status: 'error', message: 'Sem acesso.' });
    }

    try {
      setProgressHash({ status: "pending", message: "Verificando hash", value: 50 });
      const response = await LoginOperations.verifyHash(token);
      setLoading(false);
      setProgressHash({
        status: response.status,
        message: response.message,
      });
    } catch (error: string | unknown) {
      setLoading(false);
      setUserMessage('Erro de rede');
      setProgressHash({
        status: 'error',
        message: 'Erro de rede',
      });
    }
  };

  const verifyEmail = async () => {
    setProgressEmail({ status: "pending", message: "Verificando Email", value: 20 });
    setLoading(true);
    setUserMessage("Verificando usuário");

    try {
      setProgressEmail({ status: "pending", message: "Verificando Usuário", value: 50 });
      const response = await LoginOperations.verifySSOUser(token);
      setLoading(false);
      setProgressEmail({
        status: response.status,
        message: response.message,
      });
    } catch (error: string | unknown) {
      setLoading(false);
      setUserMessage('Erro de rede');
      setProgressHash({
        status: 'error',
        message: 'Erro de rede',
      });
    }
  };

  const verifyAccess = async () => {
    setProgressAccess({ status: "pending", message: "Verificando acesso", value: 20 });
    setUserMessage("Verificando acesso");
    
    await dispatch(LoginOperations.login(token));

    setProgressAccess({ status: "pending", message: "Verificando acesso", value: 70 });
    const userInfo = userData();

    if (userInfo) handleUserAccess();
  };

  const handleUser = () => {
    const userInfo = userData();

    if (!userInfo) {
      verifyHash();
    } else {
      handleUserAccess();
    }
  }

  React.useEffect(() => {
    handleUser();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (progressHash.status === 'success') {
      verifyEmail();
    }
    // eslint-disable-next-line
  }, [progressHash]);

  React.useEffect(() => {
    if (progressEmail.status === 'success') {
      verifyAccess();
    }
    // eslint-disable-next-line
  }, [progressEmail]);

  return (
    <Box sx={{
      backgroundImage: `url(${backImage})`,
      backgroundSize: 'cover',
      width: '100%',
      height: window.innerHeight,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: "#0002",
      display: 'flex',
      borderWidth: 2,
      borderColor: '#142878',
    }}>
      <Box>
        <Box sx={{ textAlign: "center", margin: 1 }}>
          <img src={logo} alt="LogoInit" />
        </Box>
        <Box sx={{
          backgroundColor: "#FFF7",
          borderRadius: 2,
          justifyContent: "center",
          paddingTop: 1,
          paddingBottom: 1,
          border: '3px solid #142878',
          }}>
          <Box sx={{
            width: "100%",
            textAlign: "center",
            backgroundColor: "#142878",
            marginTop: -1,
            borderTopRightRadius: 6,
            borderTopLeftRadius: 6,
          }}>
            <Typography variant="body1" fontSize={20} align="center" padding={1}>
              <strong style={{ color: "#fff"}}>{`${userMessage} `}</strong>
              {loading && <CircularProgress size={14} />}
            </Typography>
          </Box>

          <div style={{ marginTop: 20 }} />
          <Card status={progressHash.status} title="Hash" value={progressHash.value} />

          {progressHash.status === "success" && (
            <Card status={progressEmail.status} title="E-mail" value={progressEmail.value} />
          )}

          {progressEmail.status === "success" && (
            <Card status={progressAccess.status} title="Acesso" value={progressAccess.value} />
          )}

          {progressHash.status === "error" && (
            <CardError progress={progressHash} />
          )}
          {progressEmail.status === "error" && (
            <CardError progress={progressEmail} />
          )}
          {HasError && (
            <CardError progress={progressAccess} />
          )}

          {progressAccess.status === 'success' && (
            <Box sx={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color='primary'
                endIcon={<CircularProgress size={14} sx={{ color: "white" }} />}
              >
                Redirecionando
              </Button>
            </Box>
          )}
        </Box>
        <Box sx={{ textAlign: "center", margin: 1, marginTop: 4 }}>
          <img src={students} style={{ width: 180 }} alt="Logo" />
        </Box>
      </Box>
    </Box>
  )
}

export default Login;