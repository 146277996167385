import SpeakingTypes from './Types';

const SpeakingActions = {
  SetLoading: () => ({
    type: SpeakingTypes.SET_SPEAKING_LOADING
  }),
  SetFailure: () => ({
    type: SpeakingTypes.SET_SPEAKING_FAILURE
  }),
  SetSuccess: (payload: any) => ({
    type: SpeakingTypes.SET_SPEAKING_SUCCESS,
    payload
  }),
}

export default SpeakingActions;