import LevelsTypes from "./Types";

const LevelsActions = {
  SetLoading: () => ({
    type: LevelsTypes.SET_LEVELS_LOADING
  }),
  SetFailure: () => ({
    type: LevelsTypes.SET_LEVELS_FAILURE
  }),
  SetSuccess: (payload: any) => ({
    type: LevelsTypes.SET_LEVELS_SUCCESS,
    payload
  }),
}

export default LevelsActions;