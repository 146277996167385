import Api from "../Helpers/Api";

class GamesService {
  getGames() {
    return Api.get(`/jogos`);
  }

  createGames(data: any) {
    return Api.post(`/jogos`, data);
  }

  deleteGame(id: number) {
    return Api.get(`/jogos/delete/${id}`);
  }

  updateGame(data: any) {
    return Api.post(`/jogos/update`, data);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new GamesService();