import BooksTypes from './Types';

const BooksActions = {
  SetLoading: () => ({
    type: BooksTypes.SET_BOOKS_LOADING
  }),
  SetFailure: () => ({
    type: BooksTypes.SET_BOOKS_FAILURE
  }),
  SetSuccess: (payload: any) => ({
    type: BooksTypes.SET_BOOKS_SUCCESS,
    payload
  }),
}

export default BooksActions;