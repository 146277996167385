import React from "react";
import { Container } from "./style";
import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import theme from "../../Theme";
import { navigateToUrl } from "single-spa";
import { menuEtapaIdioma } from "./components/menuConfig";
import { MenuIdiomas } from "./components/types";

const Menu: React.FC = () => {
  const [pathName, setPathName] = React.useState<string>("");
  // eslint-disable-next-line
  const [expandMenu, setExpandMenu] = React.useState<boolean>(false);
  const logo = require("../../assets/media/logo.png");

  const getActualUrl = React.useCallback(() => {
    const urlPathName = window.location.pathname;
    setPathName(urlPathName);
    // eslint-disable-next-line
  }, [pathName]);

  React.useEffect(() => {
    getActualUrl();
    setTimeout(() => setExpandMenu(true), 2000);
    // eslint-disable-next-line
  }, []);
  
  const handleClick = (route: string) => {
    setPathName(route);
    navigateToUrl(route);
  };

  return (
    <Container>
      <div className="menu-header">
        <div className="menu-header-title">
          <img src={logo} alt="Logo" />
        </div>
      </div>
      <div className="menu-body">
        <div className="menu-body-content">
          {menuEtapaIdioma.map((item: any, index: number) => (
            <div
              key={index}
              onClick={() => !item.subItem && handleClick(item.route)}
              style={{
                padding: item.show ? "0.5rem 0" : 0,
              }}
              className="menu-item">

              {!item.subItem && item.show && (
                <div
                  style={{ backgroundColor: pathName === item.route ? theme.palette.primary.dark : theme.palette.secondary.main}}
                  className="menu-item-header">
                  {item.icon}
                  <div
                    className="label"
                    style={{
                      color: pathName === item.route
                        ? theme.palette.secondary.contrastText
                        : theme.palette.primary.main }}>
                        {item.label}
                  </div>
                </div>
              )}

              {item.subItem && (
                <div className="menu-subitem">
                  <Accordion
                    elevation={0}
                    className="menu-subitem-header">

                    <AccordionSummary
                      expandIcon={<ExpandMore style={{ color: theme.palette.primary.main }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="menu-subitem-header-title"
                    >
                      {item.icon}
                      <div className="label" style={{ marginLeft: 10 }}>
                          {item.label}
                      </div>
                    </AccordionSummary>

                    <AccordionDetails style={{ padding: '0rem 1rem' }}>
                      <div className="submenu">
                        {item.subItem.map((subItem: MenuIdiomas, index: number) => (
                          <div
                            key={index}
                            onClick={() => handleClick(subItem.route)}
                            style={{
                              backgroundColor:
                                pathName === subItem.route
                                ? theme.palette.primary.dark
                                : theme.palette.secondary.main,
                              color: theme.palette.primary.main,
                            }}
                            className="submenu-item">
                            {subItem.icon}
                            <div
                              // onClick={() => handleClick(subItem.route)}
                              style={{
                                color:
                                  pathName === subItem.route
                                  ? theme.palette.secondary.contrastText
                                  : theme.palette.primary.main,
                              }}
                              className="submenu-item-label">
                              {subItem.label}
                            </div>
                          </div>
                        ))}
                      </div>
                    </AccordionDetails>

                  </Accordion>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
}

export default Menu;