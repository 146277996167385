import Api from "../Helpers/Api";

class SettingsService {
  getSettings() {
    return Api.get(`/configuracoes`);
  }

  updateSettings(formData: FormData) {
    return Api.post(`/configuracoes/update`, formData);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new SettingsService();