import React from "react";
import AccountMenu from "../AccountMenu";
import { Box, Typography } from "@mui/material";
import { SkeletonGrid } from "../../Components/Skeleton";
import { ResponseProps } from "../../@types/types";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../InfraStructure/store";
import RoomsOperations from "../../InfraStructure/Rooms/RoomsOperations";
import { useParams } from "react-router-dom";
import HoursRoom from "./components/HoursRoom";

export default function RoomHours() {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = React.useState<boolean>(false);
  // eslint-disable-next-line
  const [error, setError] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any[]>([]);

  const dispatch = useDispatch<AppDispatch>();

  const getRoomHours = async () => {
    setLoading(true);
    try {
      const response = await dispatch(RoomsOperations.getRoomHours(Number(id)));
      setData(response.data);
      setLoading(false);
    } catch (error: string | unknown) {
      console.log('Erro buscando horários: ', error);
      setLoading(false);
      setError(true);
    }
  }

  const handleSave = async (data: any): Promise<ResponseProps> => {
    try {
      if (data.isNew) {
        const newData = { ...data, id_sala: Number(id) };
        const response = await dispatch(RoomsOperations.saveHourRoom(newData));
        return response;
      } else {
        const response = await dispatch(RoomsOperations.updateHourRoom(data));
        return response;
      }
    } catch (error: string | unknown) {
      console.log('Erro salvando horários: ', error);
      return { message: 'Erro ao salvar horários', status: 'error' };
    }
  }

  const handleDelete = async (id: any): Promise<boolean> => {
    try {
      const response = await dispatch(RoomsOperations.deleteHourRoom(id));
      return response.status === 'success';
    } catch (error: string | unknown) {
      console.log('Erro deletando horários: ', error);
      return false;
    }
  }

  React.useEffect(() => {
    getRoomHours();
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <AccountMenu />

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'flex-start' }}>
        <Box sx={{ width: "95%", margin: 'auto', padding: 2 }}>
          <Typography
            variant="h5"
            sx={{ color: "#000", fontWeight: "bold", marginTop: 2, marginBottom: 2 }}>
            Horários {data && data?.length > 0 ? `da sala "${data[0].sala}"` : ''}
          </Typography>
          
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              marginBottom: 3,
            }}>
          </Box>
          
          {loading
            ? <SkeletonGrid />
            : <HoursRoom
                data={data}
                onSave={handleSave}
                onDelete={handleDelete}
              />
          }
        </Box>
      </Box>
    </React.Fragment>
  );
}