import Api from "../Helpers/Api";

class HomeworksService {
  getHomeworks() {
    return Api.get(`/tarefas`);
  }


  getHomework(id: number) {
    return Api.get(`/tarefas/get/${id}`);
  }

  getTypes() {
    return Api.get('/tarefas/get_types');
  }  

  createHomework(formData: FormData) {
    return Api.post(`/tarefas`, formData);
  }

  updateHomework(formData: FormData) {
    return Api.post(`/tarefas/update`, formData);
  }

  deleteHomework(id: number) {
    return Api.get(`/tarefas/delete/${id}`);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new HomeworksService();