import StudentsTypes from './Types';

const Students = {
  SetLoading: () => ({
    type: StudentsTypes.SET_STUDENTS_LOADING
  }),
  SetFailure: () => ({
    type: StudentsTypes.SET_STUDENTS_FAILURE
  }),
  SetSuccess: (payload: any) => ({
    type: StudentsTypes.SET_STUDENTS_SUCCESS,
    payload
  }),
}

export default Students;