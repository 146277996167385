import React from "react";
import AccountMenu from "../AccountMenu";
import { Box, Typography } from "@mui/material";
import { SearchField } from "../../Components/Search";
import ShowFeedback from "./components/ShowFeedback";
import { SkeletonGrid } from "../../Components/Skeleton";
import FeedBackOperations from "../../InfraStructure/FeedBack/FeedBackOperations";
import Toast from "../../Common/Helpers/Toast";
import Failure from "../../Components/Failure";

export default function FeedBack() {
  const [data, setData] = React.useState<any[]>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [filteredData, setFilteredData] = React.useState<any[]>();

  const openToast = (message: string) => {
    setMessage(message);
    setOpen(true);
  }

  const getFeedback = async () => {
    setLoading(true);
    try {
      const response = await FeedBackOperations.getFeedBack();
      setData(response);
      setFilteredData(response);
      setLoading(false);
    } catch (error: string | unknown) {
      console.log('Erro buscando feedback: ', error);
      setError(true);
      setLoading(false);
      openToast('Erro na rede. Tente novamente...');
    }
  };

  React.useEffect(() => {
    getFeedback();
    // eslint-disable-next-line
  }, []);

  if (error) {
    return (
      <React.Fragment>
        <Toast message={message} open={open} setclose={setOpen} severity="error" />
        <Failure message='Erro ao buscar avaliações.' onClick={getFeedback} />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <AccountMenu />

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'flex-start' }}>
        <Box sx={{ width: "95%", margin: 'auto', padding: 2 }}>
          <Typography
            variant="h5"
            sx={{ color: "#000", fontWeight: "bold", marginTop: 2, marginBottom: 2 }}>
            Feedback de Jogos
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              marginBottom: 3,
            }}>
            <SearchField
              width={365}
              data={data}
              setSearch={setFilteredData}
              columnsToSearch={['aluno', 'jogo', 'pergunta', 'avaliacao', 'nivel']}
              placeHolder="Buscar por nome, jogo, pergunta, avaliação, curso"
            />
          </Box>

          {loading ? <SkeletonGrid /> : <ShowFeedback data={filteredData} />}
        </Box>
      </Box>
    </React.Fragment>
  );
}