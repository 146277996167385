import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#8F82DE",
      contrastText: "#232159",
      dark: "#000000",
    },
    secondary: {
      main: "#343585",
      contrastText: "#FFFFFF",
    },
  },
});

export default theme;